import { DownloadSimple } from '@phosphor-icons/react';
import { dictionary, formatAgeCategory, formatSubject } from '@wildscreen/core/src/core';
import { testIds } from '@wildscreen/core/src/core/testIds';
import { EducationResourceIcon } from '@wildscreen/ui-components/src/components/educationResourceIcons';
import * as React from 'react';

import styles from './educationResource.module.scss';

export interface IProps {
  heading?: string;
  subjects?: string[] | null;
  ageRange?: string;
  resourceType?: string;
  description?: string;
  tags?: React.ReactNode;
  downloadUrl?: string;
  downloadFilename?: string;
}

export const EducationResource: React.FC<IProps> = ({
  heading,
  subjects,
  ageRange,
  resourceType,
  description,
  tags,
  downloadUrl,
  downloadFilename,
}) => {
  const filename = React.useMemo(() => {
    return downloadFilename || downloadUrl?.split('/').pop();
  }, [downloadUrl, downloadFilename]);

  return (
    <div data-testid={testIds.authenticated.components.educationResource('resource')} className={styles.resource}>
      <div data-testid={testIds.authenticated.components.educationResource('head')} className={styles.head}>
        <div className={styles.left}>
          <h2 data-testid={testIds.authenticated.components.educationResource('heading')}>{heading}</h2>
          {!!subjects?.length && (
            <div className={styles.subtitle}>
              {dictionary.en.arkive.unauthenticated.views.educationResources.subjects}:{' '}
              {subjects.map(formatSubject).join(', ')}
            </div>
          )}
          {ageRange && (
            <div className={styles.subtitle}>
              {dictionary.en.arkive.unauthenticated.views.educationResources.ageCategory}: {formatAgeCategory(ageRange)}
            </div>
          )}
        </div>
        <div className={styles.right}>
          <div className={styles.typeContainer} data-type={resourceType}>
            <EducationResourceIcon resourceType={resourceType} />
            <div className={styles.type}>{resourceType}</div>
          </div>
        </div>
      </div>
      {tags && (
        <div data-testid={testIds.authenticated.components.educationResource('tags')} className={styles.tags}>
          {tags}
        </div>
      )}
      <div
        data-testid={testIds.authenticated.components.educationResource('description')}
        className={styles.description}
      >
        {description}
      </div>
      <div className={styles.buttons}>
        <a
          href={downloadUrl}
          data-testid={testIds.authenticated.components.educationResource('download')}
          className={styles.download}
          download={filename}
          target="_blank"
          rel="noreferrer"
        >
          {dictionary.en.arkive.unauthenticated.views.educationResources.downloadButton}
          <DownloadSimple />
        </a>
      </div>
    </div>
  );
};
