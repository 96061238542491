import * as React from 'react';

import { addMaxWidthToImageLocation } from '../helpers/imageHelpers';

export function useRenderAsset(maxWidth: number, isVideo?: boolean, title?: string, assetLocation?: string) {
  return React.useMemo(() => {
    if (isVideo) {
      const posterAssetLocation = `${assetLocation?.substring(0, assetLocation.lastIndexOf('/'))}/posterImage0.png`;
      return (
        <video
          onContextMenu={e => e.preventDefault()} // Disables right-click on video
          poster={posterAssetLocation}
          muted
          controls
          loop
          playsInline
          src={assetLocation}
        >
          <track kind="captions" />
        </video>
      );
    }

    const imageUri = addMaxWidthToImageLocation(assetLocation, maxWidth);

    return <img onContextMenu={e => e.preventDefault()} src={imageUri} alt={`${title} Preview`} loading="lazy" />;
  }, [assetLocation, isVideo, title, maxWidth]);
}
