import { useDictionary } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';

import { BackButton } from '../../components/backButton';
import { Species } from '../../components/exploreClassification/species';
import { useShowSearch } from '../../contexts/header';
import { useGetExploreDetails } from '../../hooks/useGetExploreDetails';

import styles from './exploreClassification.module.scss';

export const ExploreClassificationView: React.FC = () => {
  const { general } = useDictionary('en');
  useShowSearch();
  const isMobile = useIsMobile();

  const { description, targetProperty, classificationSearchTerm } = useGetExploreDetails();

  return (
    <div className={styles.container}>
      {!isMobile && <BackButton />}
      <div className={styles.headings}>
        <h3>{general.searchResultsFor}</h3>
        <h2>{classificationSearchTerm}</h2>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.content}>
        <Species targetProperty={targetProperty} classificationSearchTerm={classificationSearchTerm} />
      </div>
    </div>
  );
};
