import { MagnifyingGlass } from '@phosphor-icons/react';
import { routes, testIds } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './searchItem.module.scss';

export interface ISearchItemProps {
  searchItem: string;
  wildscreenId?: string;
}

export const SearchItem: React.FC<ISearchItemProps> = ({ searchItem, wildscreenId }) => {
  return (
    <Link to={routes.authenticated.arkive.taxonomy({ wildscreenId })}>
      <div
        data-testid={testIds.authenticated.components.searchItem(searchItem, 'container')}
        className={styles.container}
      >
        <div>{searchItem}</div>
        <MagnifyingGlass />
      </div>
    </Link>
  );
};
