import * as React from 'react';

export function useContainerWidth(containerRef) {
  const [containerWidth, setContainerWidth] = React.useState(0);
  const prevContainerWidth = React.useRef(containerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const newWidth = containerRef.current.offsetWidth;
        if (newWidth !== prevContainerWidth.current) {
          setContainerWidth(newWidth);
          prevContainerWidth.current = newWidth;
        }
      }
    };

    handleResize(); // Initialize the width
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [containerRef]);

  return containerWidth;
}
