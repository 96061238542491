import { ICollectionTileSize, tileSizes } from '@wildscreen/ui-components/src/types/common';
import * as React from 'react';

import { useContainerWidth } from './useContainerWidth';

const numberOfTiles = 8;

export function useContainerSize(size: ICollectionTileSize = 'small') {
  const contentRef = React.useRef(null);
  const width = useContainerWidth(contentRef);
  const canScroll = width < numberOfTiles * tileSizes[size].width; // The small Collection Tile is 107px width, so 7 tiles is 749px and 8 is 856px and width padding is 16px

  return {
    contentRef,
    canScroll,
  };
}
