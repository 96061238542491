import { MagnifyingGlass } from '@phosphor-icons/react';
import { Input, Spinner, useDebounce } from '@rocketmakers/armstrong';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';

import { apiHooks } from '../../api';
import { SearchItem } from './searchItem';

import styles from './search.module.scss';

export interface ISearchProps {
  onFoundResults: (e: boolean) => void;
}

export const Search: React.FC<ISearchProps> = ({ onFoundResults }) => {
  const [actualValue, setActualValue, throttledValue] = useDebounce<string | null>(500);
  const isMobile = useIsMobile();
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const [{ data, isFetching }] = apiHooks.ark.arkClassificationSearchPost.useQuery({
    parameters: {
      classificationSearchQuery: {
        search: (throttledValue?.length && throttledValue?.length > 2 && throttledValue) || undefined,
        size: (throttledValue?.length && throttledValue?.length > 2 && 5) || 0,
        from: 0,
        jitter: true,
      },
    },
    cacheKey: p => `${p?.classificationSearchQuery?.search}`,
  });

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  React.useEffect(() => {
    if (data?.data?.results?.length === 0 || actualValue === '') {
      onFoundResults(false);
    } else {
      onFoundResults(true);
    }
  }, [isFetching, onFoundResults, data, actualValue]);

  return (
    <div className={styles.container}>
      <Input
        ref={inputRef}
        value={actualValue}
        onChange={e => setActualValue(e.target.value)}
        leftOverlay={<MagnifyingGlass />}
        rightOverlay={isFetching && <Spinner />}
        placeholder={isMobile ? 'Search' : 'Search for species, adaptations, behaviours...'}
      />
      {data?.data?.results?.length === 0 &&
        actualValue !== '' &&
        throttledValue &&
        throttledValue !== null &&
        throttledValue !== '' &&
        throttledValue.length > 2 && <h2>{`No results for "${actualValue}"`}</h2>}
      <div className={styles.searchItems}>
        {data?.data?.results?.map((x, idx) => (
          <SearchItem
            key={idx}
            searchItem={x.friendlyName ?? (x.scientificNames?.[0] || '')}
            wildscreenId={x.wildscreenId}
          />
        ))}
      </div>
    </div>
  );
};
