import { Spinner } from '@rocketmakers/armstrong';
import { AssetListProperty } from '@wildscreen/api/src/apiClients';
import { routes, TAssetProperty } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { BackButton } from '../../components/backButton';
import { CollectionTile } from '../../components/collectionTile';
import { useShowSearch } from '../../contexts/header';

import defaultTile from '@wildscreen/ui-components/src/assets/pngs/defaultTile.png';

import styles from './assetTag.module.scss';

export const AssetTagView: React.FC = () => {
  const { targetProperty } = useParams<TAssetProperty>();
  const isMobile = useIsMobile();
  useShowSearch();
  const [{ data, isFetching }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: {
      targetProperty: targetProperty as AssetListProperty,
    },
    cacheKey: p => `${p?.targetProperty}`,
  });
  return (
    <div className={styles.container}>
      {!isMobile && <BackButton />}
      <div className={styles.headings}>
        <h2>{targetProperty?.split(/(?=[A-Z])/).join(' ')}</h2>
      </div>
      <div className={styles.gridContainer}>
        <div className={styles.grid}>
          {isFetching ? (
            <Spinner />
          ) : (
            data?.data?.tags?.map(tag => {
              let coverImage = defaultTile;
              if (tag.coverImageLocation && tag.coverImageLocation !== '') {
                coverImage = tag.coverImageLocation;
              }

              return (
                <CollectionTile
                  key={tag.name}
                  size="large"
                  to={routes.authenticated.arkive.exploreAsset({
                    assetProperty: targetProperty,
                    assetSearchTerm: tag.name,
                  })}
                  coverImage={coverImage}
                  title={tag.name ?? ''}
                  isLoading={isFetching}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
