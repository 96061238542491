import { TaxonRank } from '@wildscreen/api/src/apiClients';

export type TLogoSize = 'small' | 'large';

export type TTaxonomyRanks = TaxonRank | 'Domain';

export type ICollectionTileSize = 'small' | 'medium' | 'large';

export const tileSizes: Record<ICollectionTileSize, { width: number; height: number }> = {
  small: { width: 100, height: 100 },
  medium: { width: 107, height: 100 },
  large: { width: 142, height: 132 },
};
