import { testIds } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';

import { Socials } from '../../socials/socials';
import { FooterDataSources } from './components/dataSource';
import { FooterBottomLeft, FooterBottomRight } from './components/decorations';
import { Links } from './components/links';
import { FooterLogo } from './components/logo';
import { Rocketmakers } from './components/rocketmakers';

import styles from './footer.module.scss';

export const Footer: React.FC = () => {
  const isMobile = useIsMobile();
  return (
    <footer data-testid={testIds.authenticated.components.footer('container')}>
      <div data-testid={testIds.authenticated.components.footer('wrapper')} className={styles.footerWrapper}>
        <div data-testid={testIds.authenticated.components.footer('content')} className={styles.footerContent}>
          <div data-testid={testIds.authenticated.components.footer('logo')} className={styles.logo}>
            <FooterLogo />
          </div>
          <div data-testid={testIds.authenticated.components.footer('links')} className={styles.links}>
            <Links />
          </div>
          <div data-testid={testIds.authenticated.components.footer('socials')} className={styles.socials}>
            <Socials />
          </div>
          {!isMobile && (
            <div className={styles.rocketmakers}>
              <Rocketmakers />
            </div>
          )}
        </div>
        <FooterDataSources>{isMobile && <Rocketmakers />}</FooterDataSources>
        <FooterBottomLeft />
        <FooterBottomRight />
      </div>
    </footer>
  );
};
