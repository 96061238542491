import { SearchAsset } from '@wildscreen/api/src/apiClients';
import { routes, testIds } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useRenderAsset } from '../../hooks/useRenderAsset';

import styles from './galleryAsset.module.scss';

export interface IGalleryAssetProps {
  asset?: SearchAsset;
  mode?: 'home' | 'gallery';
  isFetching?: boolean;
  maxWidth: number;
}

export const GalleryAsset: React.FC<IGalleryAssetProps> = ({ maxWidth, asset, isFetching, mode = 'gallery' }) => {
  const assetRenderer = useRenderAsset(maxWidth, asset?.isVideo, asset?.title, asset?.assetLocation);

  return (
    <Link
      data-loading={isFetching}
      data-testid={testIds.authenticated.components.galleryAsset(`asset-link-${asset?.assetId}`)}
      className={styles.assetContainer}
      to={routes.authenticated.arkive.asset({ assetId: asset?.assetId })}
    >
      <div
        data-testid={testIds.authenticated.components.galleryAsset(`asset-${asset?.assetId}`)}
        className={styles.asset}
      >
        {assetRenderer}
      </div>
      {mode === 'gallery' && (
        <>
          <div data-loading={isFetching} className={styles.gradient} />
          <div data-loading={isFetching} className={styles.collectionName}>
            {asset?.title}
          </div>
        </>
      )}
    </Link>
  );
};
