import * as React from 'react';

import styles from './header.module.scss';

export const HeaderLogo: React.FC<{ color: 'white' | 'primary' | 'hide' }> = ({ color }) => {
  return (
    <svg className={styles.headerLogo} viewBox="0 0 235 141" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path data-color={color} d="M33.8732 132.769V140.658H0.984375V107.768H9.12633V132.769H33.8732Z" />
      <path data-color={color} d="M234.744 107.768V140.658H201.852V132.514H226.609V107.768H234.744Z" />
      <path data-color={color} d="M33.8732 0.442627V8.48953H9.12274V33.335H0.984375V0.442627H33.8732Z" />
      <path data-color={color} d="M234.744 0.442627V33.335H226.606V8.89304H201.852V0.442627H234.744Z" />
      <path
        data-color={color}
        d="M79.6936 37.3763H75.2324L72.5909 24.4322L70.1548 37.3763H65.9867L62.377 17.447H67.1314L68.9512 29.745L71.3584 17.447H74.7926L77.5218 29.7721L79.195 17.447H83.0102L79.6936 37.3763Z"
      />
      <path
        data-color={color}
        d="M84.3887 16.8889H88.4102V20.3819H84.3887V16.8889ZM84.3887 22.7302H88.4102V37.3765H84.3887V22.7302Z"
      />
      <path
        data-color={color}
        d="M94.5469 37.3763H92.3163C91.1416 37.3763 90.5254 36.789 90.5254 35.5564V17.447H94.5469V37.3763Z"
      />
      <path
        data-color={color}
        d="M96.4219 32.562V27.5433C96.4219 23.8159 97.9774 22.4079 100.237 22.4079C102.086 22.4079 103.025 23.406 103.412 24.3743V17.447H107.403V37.3763H103.407V35.5266C103.025 36.5835 102.086 37.6694 100.266 37.6694C97.9774 37.6694 96.4219 36.0849 96.4219 32.562ZM103.378 32.1811V27.8953C103.378 26.6339 102.818 25.9877 101.91 25.9877C101.03 25.9877 100.442 26.604 100.442 27.8953V32.1811C100.442 33.4425 101.03 34.0298 101.91 34.0298C102.82 34.0298 103.378 33.4425 103.378 32.1811Z"
      />
      <path
        data-color={color}
        d="M108.984 33.3847V32.7955L111.92 32.4724V33.1475C111.92 34.4977 112.387 35.1139 113.414 35.1139C114.294 35.1139 114.793 34.6153 114.793 33.5882C114.793 32.7955 114.441 32.3847 113.825 32.0616L111.513 30.9187C109.898 30.0971 109.165 28.8646 109.165 27.0448C109.165 24.4612 110.574 22.407 114.066 22.407C116.971 22.407 118.641 23.816 118.641 26.281V27.0149L115.735 27.309V26.633C115.735 25.6937 115.412 25.1064 114.503 25.1064C113.622 25.1064 113.24 25.6666 113.24 26.5153C113.24 27.1904 113.504 27.5723 114.444 28.042L116.733 29.1858C118.321 29.9785 118.964 31.211 118.964 32.943C118.964 35.3791 117.731 37.6686 113.74 37.6686C110.658 37.6695 108.984 36.2316 108.984 33.3847Z"
      />
      <path
        data-color={color}
        d="M130.353 31.7404V32.0047C130.353 36.2316 128.034 37.6695 125.246 37.6695C121.698 37.6695 120.227 35.3212 120.227 32.2101V27.8954C120.227 24.9019 121.607 22.407 125.246 22.407C127.917 22.407 130.353 23.7571 130.353 27.8954V28.3949L127.213 28.6591V27.8954C127.213 26.6339 126.652 25.9878 125.745 25.9878C124.837 25.9878 124.277 26.6339 124.277 27.8954V32.1811C124.277 33.4426 124.837 34.0299 125.745 34.0299C126.625 34.0299 127.213 33.4426 127.213 32.1811V31.4762L130.353 31.7404Z"
      />
      <path
        data-color={color}
        d="M131.936 22.7301H135.957V25.1661C136.573 23.1119 137.894 22.407 139.772 22.407L139.743 26.7506C139.276 26.4864 138.481 26.3986 138.129 26.3986C136.486 26.3986 135.957 27.4845 135.957 29.5098V37.3763H131.936V22.7301Z"
      />
      <path
        data-color={color}
        d="M166.512 22.7303V24.6724C166.864 23.6154 168.126 22.4119 169.916 22.4119C172.064 22.4119 173.35 23.6742 173.35 26.6089V37.3765H169.33V27.8368C169.33 26.693 168.948 26.0468 168.038 26.0468C167.129 26.0468 166.512 26.693 166.512 27.8666V37.3765H162.49V22.7303H166.512Z"
      />
      <path
        data-color={color}
        d="M145.539 22.407C144.508 22.4422 143.52 22.8307 142.741 23.5074C141.962 24.1841 141.439 25.1079 141.26 26.1241C141.259 26.1284 141.259 26.1328 141.26 26.1372C141.234 26.2812 141.216 26.4264 141.206 26.5723C141.078 27.914 141.012 31.7385 143.932 34.6396C145.549 36.2587 147.7 37.233 149.983 37.3801V33.9711C148.733 33.6611 147.971 32.6639 147.702 31.408H149.983V22.407H145.539Z"
      />
      <path
        data-color={color}
        d="M155.797 22.407C154.765 22.4422 153.777 22.8307 152.998 23.5074C152.219 24.1841 151.697 25.1079 151.517 26.1241V26.1372C151.492 26.2812 151.474 26.4264 151.464 26.5723C151.336 27.914 151.27 31.7385 154.191 34.6396C155.808 36.2583 157.96 37.2316 160.244 37.3773V33.9711C158.994 33.6611 158.232 32.6639 157.963 31.408H160.247V22.407H155.797Z"
      />
      <path
        data-color={color}
        d="M45.6895 120.641L61.5566 48.8155H80.9145L94.8775 120.641H77.1063L74.8849 107.524H61.4508L59.0178 120.641H45.6895ZM68.4323 67.0098L62.9317 95.4648H73.5098L68.4323 67.0098Z"
      />
      <path
        data-color={color}
        d="M117.899 79.8093C122.236 79.8093 124.352 77.6936 124.352 73.3566V68.9138C124.352 64.7884 122.236 62.7786 117.899 62.7786H112.821V79.8093H117.899ZM140.536 118.842L140.748 120.641H128.794C127.208 120.641 126.044 120.112 125.409 119.054C124.775 117.996 124.352 116.515 124.246 114.717L123.399 97.8978C123.082 93.3492 120.967 91.022 117.053 91.022H113.033V120.535H98.2237V48.7097H121.601C127.208 48.7097 131.545 50.0849 134.612 52.9409C137.68 55.797 139.267 60.0283 139.267 65.6346V73.1451C139.267 79.2804 136.305 83.3 130.275 85.2041V85.5214C135.247 86.685 137.891 90.4931 138.209 96.9457L139.161 113.024C139.372 116.092 139.796 117.996 140.536 118.842Z"
      />
      <path
        data-color={color}
        d="M144.551 48.8155H159.255V82.1364L176.497 48.8155H190.037L175.545 77.9052L189.297 120.641H173.007L164.861 90.8105L159.255 100.86V120.641H144.551V48.8155Z"
      />
    </svg>
  );
};
