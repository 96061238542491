import { SearchAsset, SearchClassification } from '@wildscreen/api/src/apiClients';
import { routes } from '@wildscreen/core/src/core';
import { taxonKeyArray } from '@wildscreen/core/src/core/taxonomy';
import * as React from 'react';

import { apiHooks } from '../../api';
import { useGenerateTaxonomyQuery } from '../../hooks/useGenerateTaxonomyQuery';
import { BaseCollectionTiles } from '../baseCollectionTiles';

function stripSelfFromResults(data: Array<SearchClassification>, wildscreenId?: string) {
  return data?.filter(x => x.wildscreenId !== wildscreenId);
}

export interface IRelatedAssetsProps {
  searchData?: SearchAsset;
  assetId?: string;
  onlyShowDirectParents?: boolean;
}
export const RelatedAssets: React.FC<IRelatedAssetsProps> = ({ assetId, searchData }) => {
  const { query, queryKeys } = useGenerateTaxonomyQuery(searchData, true, true);

  const [multiSearch, { data, isFetching }] = apiHooks.ark.arkClassificationMultisearchPost.useMutation();

  React.useEffect(() => {
    if (query && queryKeys && queryKeys?.length > 0) {
      multiSearch({
        multiClassificationSearchQuery: {
          queriesByKey: query,
          size: 10,
        },
      });
    }
  }, [multiSearch, query, queryKeys, queryKeys?.length]);

  return (
    <>
      {queryKeys?.map(key => {
        const keySplit = key.split(':');
        const seeAllTo = taxonKeyArray.includes(keySplit[0].toLowerCase())
          ? routes.authenticated.arkive.exploreTaxonomy({ taxonomyRank: keySplit[0], taxonomySearchTerm: keySplit[1] })
          : routes.authenticated.arkive.exploreClassification({
              classificationProperty: `${keySplit[0]}`,
              classificationSearchTerm: `${keySplit[1]}`,
            });
        return (
          stripSelfFromResults(data?.data?.results?.[key]?.results || [], assetId)?.length > 0 && (
            <BaseCollectionTiles
              key={key}
              title={keySplit[1]}
              isFetching={isFetching}
              parentId={assetId}
              filterId="wildscreenId"
              data={data?.data?.results?.[key]}
              to={id =>
                routes.authenticated.arkive.taxonomy({
                  wildscreenId: id,
                })
              }
              seeAllTo={seeAllTo}
            />
          )
        );
      })}
    </>
  );
};
