import { Button, Checkbox, Dialog, useForm } from '@rocketmakers/armstrong';
import { dictionary, routes, testIds } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { CookiePreferences, ICookieOptions } from '../../contexts/cookiePreferences';

import styles from './cookieBanner.module.scss';

export interface ICookieBannerProps {
  isOpen: boolean;
  options: ICookieOptions;
  saveAndClose: (preferences: CookiePreferences) => void;
}

export const CookieBanner: React.FC<ICookieBannerProps> = ({ isOpen, options, saveAndClose }) => {
  const initialFormState: CookiePreferences = React.useMemo(() => {
    const optionKeys = Object.keys(options);
    const obj = {};
    for (const key of optionKeys) {
      obj[key] = options[key].default;
    }

    return obj as CookiePreferences;
  }, [options]);

  const { formProp, formState } = useForm(initialFormState);

  const cookieOptions = React.useMemo(() => {
    const optionKeys = Object.keys(options) as (keyof ICookieOptions)[];
    return optionKeys.map(k => {
      return (
        <div className={styles.cookiePreferenceOption} key={k}>
          <Checkbox
            bind={formProp(k).bind()}
            disabled={options[k].isDisabled}
            data-testid={testIds.unauthenticated.components.cookieBanner.preference(k)}
          />
          <div>
            <h4>{options[k].title}</h4>
            <p>{options[k].description}</p>
          </div>
        </div>
      );
    });
  }, [formProp, options]);

  const onSavePreferences = React.useCallback(() => {
    if (formState) {
      saveAndClose(formState);
    }
  }, [formState, saveAndClose]);

  return (
    <Dialog
      data={formState}
      open={isOpen}
      className={styles.container}
      onPointerDownOutside={e => e.preventDefault()}
      onEscapeKeyDown={e => e.preventDefault()}
    >
      <div className={styles.contentContainer}>
        <div className={styles.disclaimer}>
          <h2>{dictionary.en.arkive.unauthenticated.views.cookieBanner.title}</h2>
          {dictionary.en.arkive.unauthenticated.views.cookieBanner.description.map((value, index) => (
            <p key={index}>{value}</p>
          ))}

          <p>
            {dictionary.en.arkive.unauthenticated.views.cookieBanner.privacyPolicy.pre}
            <Link
              to={routes.authenticated.arkive.privacyPolicy()}
              target="_blank"
              data-testid={testIds.unauthenticated.components.cookieBanner.privacyPolicy}
            >
              {dictionary.en.arkive.unauthenticated.views.cookieBanner.privacyPolicy.linkCta}
            </Link>
            {dictionary.en.arkive.unauthenticated.views.cookieBanner.privacyPolicy.post}
          </p>
          <Button
            className={styles.cta}
            onClick={onSavePreferences}
            data-testid={testIds.unauthenticated.components.cookieBanner.cta}
          >
            {dictionary.en.arkive.unauthenticated.views.cookieBanner.cta}
          </Button>
        </div>
        <div className={styles.cookiePreferences}>
          <h3>{dictionary.en.arkive.unauthenticated.views.cookieBanner.preferences.title}</h3>
          {cookieOptions}
        </div>
        <div className={styles.ctaContainer}>
          <Button
            className={styles.cta}
            onClick={onSavePreferences}
            data-testid={testIds.unauthenticated.components.cookieBanner.cta}
          >
            {dictionary.en.arkive.unauthenticated.views.cookieBanner.cta}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
