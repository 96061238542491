import * as React from 'react';

import rmlogo from '@wildscreen/ui-components/src/assets/svgs/rocketmakers.svg';

import styles from './rocketmakers.module.scss';

export const Rocketmakers: React.FC = () => {
  return (
    <a className={styles.rmLogo} target="__blank" href="https://www.rocketmakers.com">
      <img src={rmlogo} alt="" />
    </a>
  );
};
