import * as React from 'react';

import styles from './logo.module.scss';

export const FooterLogo: React.FC<{ hideCopyright?: boolean }> = ({ hideCopyright }) => {
  return (
    <>
      <svg className={styles.footerLogo} viewBox="0 0 223 139" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M32.9392 130.477V138.2H0.73999V106H8.71121V130.477H32.9392Z" fill="white" />
        <path d="M222.596 106V138.2H190.393V130.227H214.632V106H222.596Z" fill="white" />
        <path d="M32.9392 0.925293V8.80346H8.7077V33.128H0.73999V0.925293H32.9392Z" fill="white" />
        <path d="M222.596 0.925049V33.1277H214.628V9.19827H190.393V0.925049H222.596Z" fill="white" />
        <path
          d="M76.6732 40.786H72.5832L70.1615 28.919L67.9281 40.786H64.1069L60.7975 22.5151H65.1563L66.8247 33.7898L69.0315 22.5151H72.18L74.6821 33.8146L76.2161 22.5151H79.7138L76.6732 40.786Z"
          fill="white"
        />
        <path
          d="M80.9783 22.0033H84.6652V25.2057H80.9783V22.0033ZM80.9783 27.3586H84.6652V40.7861H80.9783V27.3586Z"
          fill="white"
        />
        <path
          d="M90.2893 40.786H88.2443C87.1674 40.786 86.6024 40.2476 86.6024 39.1177V22.5151H90.2893V40.786Z"
          fill="white"
        />
        <path
          d="M92.0115 36.3724V31.7713C92.0115 28.3541 93.4376 27.0632 95.5092 27.0632C97.2041 27.0632 98.0652 27.9783 98.4196 28.866V22.5151H102.079V40.786H98.4154V39.0903C98.0652 40.0593 97.2041 41.0548 95.5357 41.0548C93.4376 41.0548 92.0115 39.6022 92.0115 36.3724ZM98.3888 36.0231V32.094C98.3888 30.9375 97.8752 30.3452 97.0432 30.3452C96.2359 30.3452 95.6975 30.9101 95.6975 32.094V36.0231C95.6975 37.1796 96.2359 37.7181 97.0432 37.7181C97.8769 37.7181 98.3888 37.1796 98.3888 36.0231Z"
          fill="white"
        />
        <path
          d="M103.528 37.127V36.5868L106.219 36.2906V36.9095C106.219 38.1474 106.647 38.7123 107.589 38.7123C108.396 38.7123 108.853 38.2552 108.853 37.3136C108.853 36.5868 108.53 36.2102 107.965 35.914L105.846 34.8662C104.366 34.1129 103.693 32.983 103.693 31.3146C103.693 28.946 104.985 27.0627 108.186 27.0627C110.85 27.0627 112.381 28.3545 112.381 30.6144V31.2872L109.717 31.5569V30.9371C109.717 30.0759 109.42 29.5375 108.587 29.5375C107.779 29.5375 107.429 30.0511 107.429 30.8292C107.429 31.4481 107.672 31.7982 108.533 32.2288L110.632 33.2775C112.087 34.0042 112.677 35.1342 112.677 36.7221C112.677 38.9554 111.547 41.0544 107.887 41.0544C105.062 41.0553 103.528 39.737 103.528 37.127Z"
          fill="white"
        />
        <path
          d="M123.117 35.6195V35.8618C123.117 39.737 120.991 41.0553 118.435 41.0553C115.182 41.0553 113.833 38.9024 113.833 36.0501V32.0944C113.833 29.35 115.098 27.0627 118.435 27.0627C120.883 27.0627 123.117 28.3005 123.117 32.0944V32.5524L120.238 32.7947V32.0944C120.238 30.9379 119.724 30.3456 118.892 30.3456C118.06 30.3456 117.547 30.9379 117.547 32.0944V36.0236C117.547 37.1801 118.06 37.7185 118.892 37.7185C119.7 37.7185 120.238 37.1801 120.238 36.0236V35.3773L123.117 35.6195Z"
          fill="white"
        />
        <path
          d="M124.569 27.3589H128.256V29.5923C128.821 27.709 130.032 27.0627 131.754 27.0627L131.727 31.045C131.299 30.8027 130.57 30.7222 130.247 30.7222C128.741 30.7222 128.256 31.7178 128.256 33.5745V40.7865H124.569V27.3589Z"
          fill="white"
        />
        <path
          d="M156.265 27.3584V29.139C156.588 28.1699 157.745 27.0665 159.386 27.0665C161.355 27.0665 162.535 28.2239 162.535 30.9143V40.786H158.849V32.04C158.849 30.9914 158.499 30.399 157.665 30.399C156.831 30.399 156.265 30.9914 156.265 32.0674V40.786H152.578V27.3584H156.265Z"
          fill="white"
        />
        <path
          d="M137.039 27.0627C136.093 27.0951 135.188 27.4512 134.474 28.0716C133.759 28.692 133.28 29.5389 133.116 30.4706C133.115 30.4745 133.115 30.4786 133.116 30.4825C133.093 30.6146 133.076 30.7477 133.067 30.8815C132.95 32.1116 132.889 35.6178 135.566 38.2775C137.048 39.7619 139.02 40.6551 141.114 40.7899V37.6646C139.967 37.3804 139.269 36.4661 139.022 35.3148H141.114V27.0627H137.039Z"
          fill="#85CFBA"
        />
        <path
          d="M146.444 27.0627C145.499 27.0951 144.593 27.4512 143.879 28.0716C143.165 28.692 142.685 29.5389 142.521 30.4706V30.4825C142.498 30.6146 142.482 30.7477 142.472 30.8815C142.355 32.1116 142.294 35.6178 144.972 38.2775C146.455 39.7614 148.428 40.6538 150.521 40.7873V37.6646C149.375 37.3804 148.677 36.4661 148.43 35.3148H150.524V27.0627H146.444Z"
          fill="#85CFBA"
        />
        <path
          d="M76.6732 40.786H72.5832L70.1615 28.919L67.9281 40.786H64.1069L60.7975 22.5151H65.1563L66.8247 33.7898L69.0315 22.5151H72.18L74.6821 33.8146L76.2161 22.5151H79.7138L76.6732 40.786Z"
          fill="white"
        />
        <path
          d="M80.9783 22.0033H84.6652V25.2057H80.9783V22.0033ZM80.9783 27.3586H84.6652V40.7861H80.9783V27.3586Z"
          fill="white"
        />
        <path
          d="M90.2893 40.786H88.2443C87.1674 40.786 86.6024 40.2476 86.6024 39.1177V22.5151H90.2893V40.786Z"
          fill="white"
        />
        <path
          d="M92.0115 36.3724V31.7713C92.0115 28.3541 93.4376 27.0632 95.5092 27.0632C97.2041 27.0632 98.0652 27.9783 98.4196 28.866V22.5151H102.079V40.786H98.4154V39.0903C98.0652 40.0593 97.2041 41.0548 95.5357 41.0548C93.4376 41.0548 92.0115 39.6022 92.0115 36.3724ZM98.3888 36.0231V32.094C98.3888 30.9375 97.8752 30.3452 97.0432 30.3452C96.2359 30.3452 95.6975 30.9101 95.6975 32.094V36.0231C95.6975 37.1796 96.2359 37.7181 97.0432 37.7181C97.8769 37.7181 98.3888 37.1796 98.3888 36.0231Z"
          fill="white"
        />
        <path
          d="M103.528 37.127V36.5868L106.219 36.2906V36.9095C106.219 38.1474 106.647 38.7123 107.589 38.7123C108.396 38.7123 108.853 38.2552 108.853 37.3136C108.853 36.5868 108.53 36.2102 107.965 35.914L105.846 34.8662C104.366 34.1129 103.693 32.983 103.693 31.3146C103.693 28.946 104.985 27.0627 108.186 27.0627C110.85 27.0627 112.381 28.3545 112.381 30.6144V31.2872L109.717 31.5569V30.9371C109.717 30.0759 109.42 29.5375 108.587 29.5375C107.779 29.5375 107.429 30.0511 107.429 30.8292C107.429 31.4481 107.672 31.7982 108.533 32.2288L110.632 33.2775C112.087 34.0042 112.677 35.1342 112.677 36.7221C112.677 38.9554 111.547 41.0544 107.887 41.0544C105.062 41.0553 103.528 39.737 103.528 37.127Z"
          fill="white"
        />
        <path
          d="M123.117 35.6195V35.8618C123.117 39.737 120.991 41.0553 118.435 41.0553C115.182 41.0553 113.833 38.9024 113.833 36.0501V32.0944C113.833 29.35 115.098 27.0627 118.435 27.0627C120.883 27.0627 123.117 28.3005 123.117 32.0944V32.5524L120.238 32.7947V32.0944C120.238 30.9379 119.724 30.3456 118.892 30.3456C118.06 30.3456 117.547 30.9379 117.547 32.0944V36.0236C117.547 37.1801 118.06 37.7185 118.892 37.7185C119.7 37.7185 120.238 37.1801 120.238 36.0236V35.3773L123.117 35.6195Z"
          fill="white"
        />
        <path
          d="M124.569 27.3589H128.256V29.5923C128.821 27.709 130.032 27.0627 131.754 27.0627L131.727 31.045C131.299 30.8027 130.57 30.7222 130.247 30.7222C128.741 30.7222 128.256 31.7178 128.256 33.5745V40.7865H124.569V27.3589Z"
          fill="white"
        />
        <path
          d="M156.265 27.3584V29.139C156.588 28.1699 157.745 27.0665 159.386 27.0665C161.355 27.0665 162.535 28.2239 162.535 30.9143V40.786H158.849V32.04C158.849 30.9914 158.499 30.399 157.665 30.399C156.831 30.399 156.265 30.9914 156.265 32.0674V40.786H152.578V27.3584H156.265Z"
          fill="white"
        />
        <path
          d="M137.039 27.0627C136.093 27.0951 135.188 27.4512 134.474 28.0716C133.759 28.692 133.28 29.5389 133.116 30.4706C133.115 30.4745 133.115 30.4786 133.116 30.4825C133.093 30.6146 133.076 30.7477 133.067 30.8815C132.95 32.1116 132.889 35.6178 135.566 38.2775C137.048 39.7619 139.02 40.6551 141.114 40.7899V37.6646C139.967 37.3804 139.269 36.4661 139.022 35.3148H141.114V27.0627H137.039Z"
          fill="#85CFBA"
        />
        <path
          d="M146.444 27.0627C145.499 27.0951 144.593 27.4512 143.879 28.0716C143.165 28.692 142.685 29.5389 142.521 30.4706V30.4825C142.498 30.6146 142.482 30.7477 142.472 30.8815C142.355 32.1116 142.294 35.6178 144.972 38.2775C146.455 39.7614 148.428 40.6538 150.521 40.7873V37.6646C149.375 37.3804 148.677 36.4661 148.43 35.3148H150.524V27.0627H146.444Z"
          fill="#85CFBA"
        />
        <path
          d="M45.4978 117.122L60.0446 51.2735H77.7917L90.5929 117.122H74.3005L72.2639 105.097H59.9476L57.7171 117.122H45.4978ZM66.3482 67.9538L61.3053 94.0411H71.0032L66.3482 67.9538Z"
          fill="#85CFBA"
        />
        <path
          d="M111.699 79.6882C115.675 79.6882 117.614 77.7487 117.614 73.7725V69.6994C117.614 65.9173 115.675 64.0747 111.699 64.0747H107.044V79.6882H111.699ZM132.452 115.473L132.646 117.122H121.687C120.233 117.122 119.166 116.637 118.584 115.667C118.002 114.698 117.614 113.34 117.517 111.691L116.741 96.2716C116.451 92.1015 114.511 89.968 110.923 89.968H107.238V117.025H93.6606V51.1765H115.093C120.233 51.1765 124.209 52.4372 127.021 55.0557C129.834 57.6741 131.288 61.5532 131.288 66.6931V73.5786C131.288 79.2033 128.573 82.8885 123.045 84.6341V84.9251C127.603 85.9918 130.028 89.4831 130.318 95.3988L131.191 110.14C131.385 112.952 131.773 114.698 132.452 115.473Z"
          fill="#85CFBA"
        />
        <path
          d="M136.133 51.2735H149.613V81.8218L165.421 51.2735H177.834L164.548 77.9426L177.155 117.122H162.221L154.753 89.774L149.613 98.987V117.122H136.133V51.2735Z"
          fill="#85CFBA"
        />
      </svg>
      {!hideCopyright && <div className={styles.copyright}>Copyright Wildscreen ARK {new Date().getFullYear()}</div>}
    </>
  );
};
