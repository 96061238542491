import { AssetListProperty } from '@wildscreen/api/src/apiClients';
import { TExploreAsset, useDictionary } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { BackButton } from '../../components/backButton';
import { Gallery } from '../../components/exploreClassification/gallery';
import { useShowSearch } from '../../contexts/header';

import styles from './exploreAsset.module.scss';

export const ExploreAssetView: React.FC = () => {
  const { assetProperty, assetSearchTerm } = useParams<TExploreAsset>();
  const { general } = useDictionary('en');
  useShowSearch();
  const isMobile = useIsMobile();

  const normalisedProperty = React.useMemo(() => {
    const lowerCaseProperty = assetProperty?.toLowerCase();
    return Object.keys(AssetListProperty).find(key =>
      [lowerCaseProperty, `${lowerCaseProperty}s`].includes(key.toLowerCase())
    ) as AssetListProperty;
  }, [assetProperty]);

  const [tagResponse] = apiHooks.data.dataPropertiesAssetPropertyTargetPropertyGet.useQuery({
    parameters: {
      name: assetSearchTerm,
      targetProperty: normalisedProperty,
    },
    cacheKey: p => `${p?.targetProperty}-${p?.name}`,
  });

  return (
    <div className={styles.container}>
      {!isMobile && <BackButton />}
      <div className={styles.headings}>
        <h3>{general.searchResultsFor}</h3>
        <h2>{assetSearchTerm}</h2>
        <div className={styles.description}>{tagResponse?.data?.data?.description}</div>
      </div>
      <div className={styles.content}>
        <Gallery targetProperty={normalisedProperty} searchTerm={assetSearchTerm} />
      </div>
    </div>
  );
};
