import { SearchAsset } from '@wildscreen/api/src/apiClients/api';
import { getTagsFromAsset } from '@wildscreen/core/src/core/taxonomy';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';

import { TCreditPosition } from './components/assetCredit';
import { Pod } from './components/base/pod';
import { PodTagContainer } from './components/podTagContainer/podTagContainer';

export type TAssetPodProps = React.PropsWithChildren<{ data?: SearchAsset }>;

export const AssetPod: React.FC<TAssetPodProps> = ({ data }) => {
  const tags = React.useMemo(() => getTagsFromAsset(data), [data]);
  const isMobile = useIsMobile();
  const creditPosition = React.useMemo<TCreditPosition>(() => {
    if (isMobile) {
      return 'in-image-bottom';
    }
    return 'in-image-top';
  }, [isMobile]);

  return (
    <Pod
      type="asset"
      heading={data?.title || ''}
      subHeading={data?.scientificNames?.[0]}
      assetLocation={data?.assetLocation}
      assetMaxWidth={1434}
      isVideo={data?.isVideo || false}
      description={data?.description}
      assetCredit={data?.credit}
      tags={<PodTagContainer tags={tags} />}
      creditPosition={creditPosition}
      libraryCredit={data?.libraryCredit}
    />
  );
};
