import * as React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';

import { apiHooks } from '../../api';
import { GalleryAsset } from '../galleryAsset';

import styles from './homepageGallery.module.scss';

export const HomepageGallery: React.FC = () => {
  const [{ data }] = apiHooks.ark.arkAssetSearchPost.useQuery({
    parameters: {
      assetSearchQuery: { isHomepageWorthy: true, jitter: true, hasPromotionLink: null, from: 0, size: 20 },
    },
  });

  return (
    <div className={styles.homepageGalleryContainer}>
      <div className={styles.inner}>
        <h3>Browse our Gallery</h3>
        <ResponsiveMasonry columnsCountBreakPoints={{ 320: 1, 500: 2, 800: 3, 1000: 4 }}>
          <Masonry>
            {data?.data.results?.map(asset => (
              <GalleryAsset maxWidth={700} key={asset.assetId} asset={asset} mode="home" />
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </div>
    </div>
  );
};
