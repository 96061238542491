import { ArrowLeft } from '@phosphor-icons/react';
import { testIds } from '@wildscreen/core/src/core';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useHeaderContext } from '../../../../contexts/header';

import styles from './header.module.scss';

export const LeftHeader: React.FC = () => {
  const { hideBackButton } = useHeaderContext();
  const navigate = useNavigate();
  return (
    <div
      data-testid={testIds.authenticated.components.header.arkive('menu-container')}
      className={styles.leftHeaderContainer}
      data-can-go-back={hideBackButton}
    >
      <div
        role="button"
        tabIndex={0}
        onKeyDown={e => e.key === 'b' && navigate(-1)}
        onClick={() => navigate(-1)}
        data-testid={testIds.authenticated.components.header.arkive(`back`)}
        className={styles.icon}
      >
        <ArrowLeft />
      </div>
    </div>
  );
};
