import { testIds } from '@wildscreen/core/src/core/testIds';
import { ICollectionTileSize, tileSizes } from '@wildscreen/ui-components/src/types/common';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { addMaxWidthToImageLocation } from '../../helpers/imageHelpers';

import styles from './collectionTile.module.scss';

export interface ICollectionTileProps {
  isLoading?: boolean;
  size: ICollectionTileSize;
  title: string;
  coverImage: string;
  to: string;
}

export const CollectionTile: React.FC<ICollectionTileProps> = ({ coverImage, size, title, to, isLoading }) => {
  const style: React.CSSProperties = {
    width: tileSizes[size].width,
    height: tileSizes[size].height,
  };

  const coverImageUrl = React.useMemo(() => {
    // Double the size for retina displays
    const imageSize = tileSizes[size].width * 2;
    return addMaxWidthToImageLocation(coverImage, imageSize);
  }, [coverImage, size]);

  return (
    <Link data-testid={testIds.authenticated.components.collectionTile(title, 'link', size)} to={to}>
      <div
        data-loading={isLoading}
        data-testid={testIds.authenticated.components.collectionTile(title, 'container', size)}
        style={style}
        className={styles.collectionTile}
      >
        <img
          data-testid={testIds.authenticated.components.collectionTile(title, 'image', size)}
          style={style}
          src={coverImageUrl}
          alt={`Cover for Collection ${title}`}
        />
        <div
          data-testid={testIds.authenticated.components.collectionTile(title, 'gradient', size)}
          className={styles.gradient}
        />
        <div
          data-testid={testIds.authenticated.components.collectionTile(title, 'title', size)}
          className={styles.collectionName}
        >
          {title}
        </div>
      </div>
    </Link>
  );
};
