import { SearchClassification } from '@wildscreen/api/src/apiClients/api';
import { capitalizeFirstLetter } from '@wildscreen/core/src/core/helpers';
import { getTagsFromClassification } from '@wildscreen/core/src/core/taxonomy';
import * as React from 'react';

import { Pod } from './components/base/pod';
import { PodTagContainer } from './components/podTagContainer/podTagContainer';

export type TTaxonomyPodProps = React.PropsWithChildren<{ data?: SearchClassification | undefined }>;

export const TaxonomyPod: React.FC<TTaxonomyPodProps> = ({ data }) => {
  const taxonomyData = data as SearchClassification;
  const tags = React.useMemo(() => getTagsFromClassification(taxonomyData), [taxonomyData]);
  return (
    <Pod
      type="taxonomy"
      assetLocation={taxonomyData?.cover?.assetLocation || ''}
      assetMaxWidth={1434}
      isVideo={false}
      heading={capitalizeFirstLetter(taxonomyData?.friendlyName || taxonomyData?.scientificNames?.[0] || '')}
      secondHeading={
        (taxonomyData?.friendlyName && capitalizeFirstLetter(taxonomyData?.scientificNames?.[0] || '')) || undefined
      }
      subHeading={taxonomyData?.taxonRank || ''}
      description={taxonomyData?.description || ''}
      assetCredit={taxonomyData?.cover?.credit || ''}
      tags={<PodTagContainer tags={tags} />}
      creditPosition="in-image-top"
    />
  );
};
