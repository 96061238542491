import { ApiHooks } from '@rocketmakers/api-hooks';
import { IValidationError, useDidUpdateEffect, useToast } from '@rocketmakers/armstrong';
import { formatValidationErrorsForArmstrong, ServerErrorResponseData } from '@wildscreen/api/src';
import { AxiosError } from 'axios';
import * as React from 'react';

export interface IProcessingResponse {
  validationErrors: IValidationError[];
}

export const useApiProcessing: ApiHooks.ProcessingHook<
  IProcessingResponse,
  unknown,
  AxiosError<ServerErrorResponseData>
> = ({ hookType, error }) => {
  const dispatch = useToast();

  const errorData = error?.response?.data;

  // Toast non-validation errors
  useDidUpdateEffect(() => {
    if (errorData && errorData?.appCode !== 'ValidationError' && errorData.appCode !== 'NotFoundError') {
      if (dispatch) {
        dispatch({
          title: 'An error has occurred.',
        });
      }
    }
  }, [errorData, dispatch]);

  // Return validation errors for mutations
  const validationErrors = React.useMemo(() => {
    if (hookType === 'mutation' && errorData?.appCode === 'ValidationError') {
      return formatValidationErrorsForArmstrong(errorData?.errors);
    }
    return [];
  }, [errorData, hookType]);

  return { validationErrors };
};
