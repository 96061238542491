import { testIds } from '@wildscreen/core/src/core/testIds';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { HomeBanner } from '../../components/homeBanner';
import { HomepageGallery } from '../../components/homepageGallery';
import { useShowSearch } from '../../contexts/header';

import styles from './home.module.scss';

export const HomeView: React.FC = () => {
  useShowSearch();

  return (
    <div data-testid={testIds.authenticated.views.welcome.view} className={styles.container}>
      <HomeBanner />
      <HomepageGallery />
      <Outlet />
    </div>
  );
};
