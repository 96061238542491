import { AssetListProperty, ClassificationListProperty } from '@wildscreen/api/src/apiClients';
import { TExploreClassification } from '@wildscreen/core/src/core';
import { taxonKeyArray } from '@wildscreen/core/src/core/taxonomy';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../api';

type TProperties = keyof typeof ClassificationListProperty | keyof typeof AssetListProperty | null;

function mapFromLowerCase(lowercaseValue: string | undefined, addSIfMissing = true): TProperties {
  const classificationMatchingEnum = Object.keys(ClassificationListProperty).find(
    key => ClassificationListProperty[key].toLowerCase() === lowercaseValue?.toLowerCase()
  );

  const assetMatchingEnum = Object.keys(AssetListProperty).find(
    key => AssetListProperty[key].toLowerCase() === lowercaseValue?.toLowerCase()
  );

  if (classificationMatchingEnum) {
    return classificationMatchingEnum as keyof typeof ClassificationListProperty;
  }

  if (assetMatchingEnum) {
    return assetMatchingEnum as keyof typeof AssetListProperty;
  }

  if (addSIfMissing) {
    // If not found and addSIfMissing is true, add 's' and try again
    return mapFromLowerCase(`${lowercaseValue}s`, false);
  }

  return null; // Return null if no match is found
}

/**
 * Custom hook for fetching the explore classification description.
 * @returns An object containing the description, targetProperty, classificationProperty, and classificationSearchTerm.
 */
export function useGetExploreDetails() {
  const { classificationProperty, classificationSearchTerm } = useParams<TExploreClassification>();
  const [description, setDescription] = React.useState<string>();
  const targetProperty = mapFromLowerCase(classificationProperty) as ClassificationListProperty;

  const searchClassification = apiHooks.ark.arkClassificationSearchPost.useRequest();
  const getClassificationTag = apiHooks.data.dataPropertiesClassificationPropertyTargetPropertyGet.useRequest();

  React.useEffect(() => {
    (async () => {
      if (classificationProperty) {
        if (taxonKeyArray.includes(classificationProperty)) {
          const res = await searchClassification({
            classificationSearchQuery: {
              [classificationProperty]: classificationSearchTerm,
              size: 1,
              from: 0,
              jitter: false,
            },
          });

          setDescription(res?.data?.results?.[0]?.description);
        } else {
          const res = await getClassificationTag({
            targetProperty,
            name: classificationSearchTerm,
          });

          setDescription(res?.data?.description);
        }
      }
    })();
  }, [searchClassification, classificationProperty, classificationSearchTerm, getClassificationTag, targetProperty]);

  return {
    description,
    targetProperty,
    classificationProperty,
    classificationSearchTerm,
  };
}
