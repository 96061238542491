import { ArrowRight } from '@phosphor-icons/react';
import { testIds } from '@wildscreen/core/src/core';
import { ICollectionTileSize, tileSizes } from '@wildscreen/ui-components/src/types/common';
import * as React from 'react';
import { Link } from 'react-router-dom';

import styles from './seeAllTile.module.scss';

export interface ISeeAllTileProps {
  to: string;
  name: string;
  size: ICollectionTileSize;
}

export const SeeAllTile: React.FC<ISeeAllTileProps> = ({ to, name, size }) => {
  const style: React.CSSProperties = {
    width: tileSizes[size].width,
    height: tileSizes[size].height,
  };
  return (
    <Link data-testid={testIds.authenticated.components.seeAllTile(name)} to={to}>
      <div data-testid={testIds.authenticated.components.seeAllTile(name)} style={style} className={styles.tile}>
        <div className={styles.inner}>
          <div className={styles.tileName}>See All</div>
          <ArrowRight />
        </div>
      </div>
    </Link>
  );
};
