import { Button } from '@rocketmakers/armstrong';
import * as React from 'react';

import { useHideSearch } from '../../contexts/header';

import styles from './errorPage.module.scss';

interface IErrorPageProps {
  title: string;
  subtitle: string;
  description: string | JSX.Element;
  stackTrace?: string;
  resetButtonCaption: string;
  resetButtonOnClick: () => void;
}

export const ErrorPage: React.FC<IErrorPageProps> = ({
  title,
  subtitle,
  description,
  stackTrace,
  resetButtonCaption,
  resetButtonOnClick,
}) => {
  useHideSearch();
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>
      <p className={styles.subtitle}>{subtitle}</p>
      <p className={styles.description}>{description}</p>
      {stackTrace && (
        <pre className={styles.stackTrace}>
          <code>{stackTrace}</code>
        </pre>
      )}
      <Button className={styles.resetButton} onClick={resetButtonOnClick} data-style="yellow-tang">
        {resetButtonCaption}
      </Button>
    </div>
  );
};
