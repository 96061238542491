import * as React from 'react';

import styles from './assetCredit.module.scss';

export type TCreditPosition = 'in-image-top' | 'in-image-bottom' | 'underneath';

export const AssetCredit: React.FC<{ credit: string; position: TCreditPosition }> = ({ credit, position }) => {
  return (
    <div data-position={position} className={styles.assetCredit}>
      <div>{credit}</div>
    </div>
  );
};
