import { AssetListProperty, ClassificationListProperty } from '@wildscreen/api/src/apiClients';
import { useDictionary } from '@wildscreen/core/src/core';
import { NoResults } from '@wildscreen/ui-components/src/components/noResults/noResults';
import * as React from 'react';

import { apiHooks } from '../../api';
import { mapTargetPropertyForQuery } from '../../helpers/targetProperty';
import { GalleryAsset } from '../galleryAsset';

import styles from './shared.module.scss';

export interface IGallery {
  searchTerm?: string;
  targetProperty?: ClassificationListProperty | AssetListProperty;
}

export const Gallery: React.FC<IGallery> = ({ searchTerm, targetProperty }) => {
  const { general } = useDictionary('en');
  const [fetchAssets, { data, isFetching }] = apiHooks.ark.arkAssetSearchPost.useMutation();

  React.useEffect(() => {
    if (searchTerm && targetProperty) {
      fetchAssets({
        assetSearchQuery: {
          isHomepageWorthy: null,
          hasPromotionLink: null,
          from: 0,
          jitter: true,
          size: 48,
          [mapTargetPropertyForQuery(targetProperty) as string]: searchTerm,
        },
      });
    }
  }, [searchTerm, fetchAssets, targetProperty]);

  const noResults = data?.data.results?.length === 0 && !isFetching;
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{general.gallery}</h3>
      <div className={styles.grid} data-no-results={noResults}>
        {data?.data?.results?.map(x => (
          <GalleryAsset maxWidth={400} key={x.assetId} mode="gallery" asset={x} />
        ))}
        {noResults && <NoResults />}
      </div>
    </div>
  );
};
