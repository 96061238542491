import { dictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useHideSearch } from '../../contexts/header';

import leopardImage from '@wildscreen/ui-components/src/assets/pngs/about/leopard.png';
import teamImage from '@wildscreen/ui-components/src/assets/pngs/about/team.png';
import whaleImage from '@wildscreen/ui-components/src/assets/pngs/about/whale.png';
import whaleMobileImage from '@wildscreen/ui-components/src/assets/pngs/about/whale-mobile.png';

import styles from './aboutUs.module.scss';
import classNames from 'classnames';

export const AboutUs: React.FC = () => {
  useHideSearch();
  return (
    <div className={styles.container}>
      <h2>{dictionary.en.arkive.unauthenticated.views.aboutUs.title}</h2>
      <div className={classNames(styles.section, styles.sectionTeam)} data-reverse="true">
        <div className={styles.imageSection}>
          <img data-contain={true} src={teamImage} alt="Team members sitting at a table" />
        </div>
        <div className={styles.contentSection}>
          <div className={styles.content}>
            <h3>{dictionary.en.arkive.unauthenticated.views.aboutUs.team.title}</h3>
            {dictionary.en.arkive.unauthenticated.views.aboutUs.team.description.map((value, index) => (
              <p key={index}>{value}</p>
            ))}
          </div>
        </div>
      </div>
      <div className={classNames(styles.section, styles.sectionWhat)} data-reverse="false">
        <div className={styles.imageSection}>
          <img src={whaleImage} alt="Whale jumping out of the water" data-for-mobile="false" />
          <img src={whaleMobileImage} alt="Whale jumping out of the water" data-for-mobile="true" />
        </div>
        <div className={styles.contentSection}>
          <div className={styles.content}>
            <h3>{dictionary.en.arkive.unauthenticated.views.aboutUs.what.title}</h3>
            {dictionary.en.arkive.unauthenticated.views.aboutUs.what.description.map((value, index) => (
              <p key={index}>{value}</p>
            ))}
          </div>
        </div>
      </div>
      <div className={classNames(styles.section, styles.sectionWhy)} data-reverse="true">
        <div className={styles.imageSection}>
          <div />
          <div />
          <div className={styles.leopardContainer}>
            <img src={leopardImage} alt="Leopard staring at user" />
          </div>
        </div>
        <div className={styles.contentSection}>
          <div className={styles.content}>
            <h3>{dictionary.en.arkive.unauthenticated.views.aboutUs.why.title}</h3>
            {dictionary.en.arkive.unauthenticated.views.aboutUs.why.description.map((value, index) => (
              <p key={index}>{value}</p>
            ))}
            <Link to="https://wildscreen.org/donate/" target="_blank">
              {dictionary.en.arkive.unauthenticated.views.aboutUs.why.cta}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
