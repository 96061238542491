import * as React from 'react';

import { PodTag } from '../podTag/podTag';

import styles from './podTagContainer.module.scss';

export interface IPodTag {
  type: string;
  tag: string;
}

interface IPodTagContainerProps {
  tags: Array<IPodTag>;
  maxTags?: number;
}

export const PodTagContainer: React.FC<IPodTagContainerProps> = ({ tags, maxTags = 7 }) => {
  const [showAllTags, setShowAllTags] = React.useState<boolean>(false);
  return (
    <div data-show-all-tags={showAllTags} className={styles.podTagContainer}>
      {tags.slice(0, showAllTags ? tags.length : maxTags).map(x => (
        <PodTag key={`${x.type}-${x.tag}`} type={x.type} content={x.tag} />
      ))}
      {!showAllTags && tags.length > maxTags && (
        <PodTag type="white" content="..." onClick={() => setShowAllTags(s => !s)} />
      )}
    </div>
  );
};
