import { Button } from '@rocketmakers/armstrong';
import { dictionary, routes } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useCookiePreferencesContext } from '../../../../contexts/cookiePreferences';

import styles from './links.module.scss';

const links = [
  {
    to: routes.authenticated.arkive.explore,
    label: dictionary.en.arkive.unauthenticated.views.navigation.explore,
    bold: true,
  },
  {
    to: routes.authenticated.arkive.termsAndConditions,
    label: dictionary.en.arkive.unauthenticated.views.navigation.termsAndConditions,
  },
  {
    to: routes.authenticated.arkive.aboutUs,
    label: dictionary.en.arkive.unauthenticated.views.navigation.aboutUs,
    bold: true,
  },
  {
    to: routes.authenticated.arkive.privacyPolicy,
    label: dictionary.en.arkive.unauthenticated.views.navigation.privacyPolicy,
  },
  {
    to: () => 'https://wildscreen.org',
    label: dictionary.en.arkive.unauthenticated.views.navigation.wildscreen,
    bold: true,
  },
];

export const Links: React.FC = () => {
  const isMobile = useIsMobile();
  const { open } = useCookiePreferencesContext();

  if (isMobile) {
    links.sort((a, b) => {
      if (a.bold && !b.bold) {
        return -1;
      }
      if (!a.bold && b.bold) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <div className={styles.navigation}>
      {links.map((link, index) => (
        <Link
          key={link.label + index}
          to={link.to()}
          data-bold={link.bold}
          state={link.to() === routes.authenticated.arkive.explore() ? { prevState: window.location.pathname } : {}}
        >
          {link.label}
        </Link>
      ))}
      <Button className={styles.cookiePreferences} onClick={open}>
        {dictionary.en.arkive.unauthenticated.views.navigation.cookiePreferences}
      </Button>
    </div>
  );
};
