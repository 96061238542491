import { TaxonRank } from '@wildscreen/api/src/apiClients';
import { routes } from '@wildscreen/core/src/core';
import * as React from 'react';

import { apiHooks } from '../../api';
import { BaseCollectionTiles } from '../baseCollectionTiles';

export interface ITaxonomyCollectionTilesProps {
  taxonRank: TaxonRank;
}

export const TaxonomyCollectionTiles: React.FC<ITaxonomyCollectionTilesProps> = ({ taxonRank }) => {
  const [{ data, isFetching }] = apiHooks.ark.arkClassificationSearchPost.useQuery({
    parameters: {
      classificationSearchQuery: {
        from: 0,
        taxonRank,
        jitter: true,
        size: 7,
      },
    },
    cacheKey: p => `${p?.classificationSearchQuery?.taxonRank}`,
  });

  return (
    <BaseCollectionTiles
      title={taxonRank}
      isFetching={isFetching}
      filterId="wildscreenId"
      data={data?.data}
      to={p => routes.authenticated.arkive.taxonomy({ wildscreenId: p })}
      seeAllTo={routes.authenticated.arkive.exploreTaxonomy({ taxonomyRank: taxonRank, taxonomySearchTerm: 'seeAll' })}
    />
  );
};
