import { ClassificationListProperty } from '@wildscreen/api/src/apiClients';
import { routes } from '@wildscreen/core/src/core';
import { shuffleArray } from '@wildscreen/core/src/core/helpers';
import * as React from 'react';

import { apiHooks } from '../../api';
import { useContainerSize } from '../../hooks/useContainerSize';
import { CollectionTile } from '../collectionTile';
import { SeeAllTile } from '../seeAllTile';

import defaultTile from '@wildscreen/ui-components/src/assets/pngs/defaultTile.png';

import styles from './classificationCollectionTiles.module.scss';

export interface IClassificationCollectionTilesProps {
  property: ClassificationListProperty;
}

export const ClassificationCollectionTiles: React.FC<IClassificationCollectionTilesProps> = ({ property }) => {
  const [{ data, isFetching }] = apiHooks.data.dataPropertiesClassificationTargetPropertyGet.useQuery({
    parameters: {
      targetProperty: property,
    },
    cacheKey: p => `${p?.targetProperty}`,
  });
  const { contentRef, canScroll } = useContainerSize('small'); // The small Collection Tile is 107px width, so 7 tiles is 749px and 8 is 856px and width padding is 16px

  const shuffledArray = React.useMemo(() => shuffleArray(data?.data?.tags || []), [data?.data?.tags]);

  const title = React.useMemo(() => {
    switch (property) {
      case ClassificationListProperty.BiologicalThemes:
        return 'Biological Themes';
      case ClassificationListProperty.LandRegions:
        return 'Global Regions';
      case ClassificationListProperty.Actions:
        return 'Conservation Actions';
      default:
        return property.split(/(?=[A-Z])/).join(' ');
    }
  }, [property]);

  return (
    <div ref={contentRef} className={styles.container}>
      <h3>{title}</h3>
      <div data-scroll={canScroll} className={styles.row}>
        {isFetching
          ? Array.from({ length: 7 }).map((_, i) => {
              return <CollectionTile isLoading={true} coverImage={''} size="small" title="" to="/" key={i} />;
            })
          : shuffledArray.slice(0, 7).map((result, i) => (
              <CollectionTile
                coverImage={result.coverImageLocation ?? defaultTile}
                size="small"
                title={result.name ?? ''}
                to={routes.authenticated.arkive.exploreClassification({
                  classificationProperty: property,
                  classificationSearchTerm: result.name,
                })}
                key={i}
              />
            ))}
        <SeeAllTile
          name={property}
          size="small"
          to={routes.authenticated.arkive.classificationProperty({
            targetProperty: property,
          })}
        />
      </div>
    </div>
  );
};
