import { Spinner } from '@rocketmakers/armstrong';
import { ClassificationSearchQuery, TaxonRank } from '@wildscreen/api/src/apiClients';
import { capitalizeFirstLetter, routes, TExploreTaxonomy } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { BackButton } from '../../components/backButton';
import { CollectionTile } from '../../components/collectionTile';
import { useShowSearch } from '../../contexts/header';

import defaultTile from '@wildscreen/ui-components/src/assets/pngs/defaultTile.png';

import styles from './exploreTaxonomy.module.scss';

export const ExploreTaxonomyView: React.FC = () => {
  const { taxonomyRank, taxonomySearchTerm } = useParams<TExploreTaxonomy>();
  const isMobile = useIsMobile();
  useShowSearch();

  const classificationSearchQuery = React.useMemo<ClassificationSearchQuery>(() => {
    if (taxonomySearchTerm !== 'seeAll') {
      return {
        from: 0,
        jitter: true,
        size: 200,
        [taxonomyRank as string]: taxonomySearchTerm,
      };
    }
    return {
      from: 0,
      jitter: true,
      size: 200,
      taxonRank: capitalizeFirstLetter(taxonomyRank as string) as TaxonRank,
    };
  }, [taxonomyRank, taxonomySearchTerm]);

  const [{ data, isFetching }] = apiHooks.ark.arkClassificationSearchPost.useQuery({
    parameters: {
      classificationSearchQuery,
    },
  });
  return (
    <div className={styles.container}>
      {!isMobile && <BackButton />}
      <div className={styles.headings}>
        <h2>
          {(taxonomySearchTerm !== 'seeAll' && taxonomyRank?.split(/(?=[A-Z])/).join(' ')) ||
            `All ${taxonomyRank?.split(/(?=[A-Z])/).join(' ')}`}
        </h2>
        <h3>{taxonomySearchTerm !== 'seeAll' && capitalizeFirstLetter(taxonomySearchTerm || '')}</h3>
      </div>
      <div className={styles.gridContainer}>
        <div className={styles.grid}>
          {isFetching ? (
            <Spinner />
          ) : (
            data?.data?.results?.map(tag => {
              let coverImage = defaultTile;
              if (tag?.cover?.assetLocation) {
                coverImage = tag?.cover?.assetLocation;
              }

              return (
                <CollectionTile
                  key={tag.wildscreenId}
                  size="large"
                  to={routes.authenticated.arkive.taxonomy({
                    wildscreenId: tag.wildscreenId,
                  })}
                  coverImage={coverImage}
                  title={tag.friendlyName ?? tag.scientificNames?.[0] ?? ''}
                  isLoading={isFetching}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};
