import { SearchClassificationSearchResult } from '@wildscreen/api/src/apiClients';
import { ICollectionTileSize } from '@wildscreen/ui-components/src/types/common';
import * as React from 'react';

import { CollectionTile } from '../collectionTile';
import { SeeAllTile } from '../seeAllTile';

import defaultTile from '@wildscreen/ui-components/src/assets/pngs/defaultTile.png';

import styles from './baseCollectionTiles.module.scss';

export interface IBaseCollectionTilesProps {
  title: string;
  isFetching?: boolean;
  parentId?: string;
  filterId: 'wildscreenId' | 'assetId';
  data?: SearchClassificationSearchResult;
  to?: (id?: string) => string;
  seeAllTo?: string;
  size?: ICollectionTileSize;
}

export const BaseCollectionRow: React.FC<IBaseCollectionTilesProps> = ({
  title,
  isFetching,
  data,
  to,
  parentId,
  filterId,
  seeAllTo,
  size = 'small',
}) => {
  if (isFetching) {
    return (
      <>
        {Array.from({ length: 7 }).map((_, i) => {
          return <CollectionTile isLoading={true} coverImage={''} size={size} title="" to="/" key={i} />;
        })}
      </>
    );
  }

  if (data?.results && data?.results?.filter(x => x[filterId] !== parentId)?.length > 0) {
    return (
      <>
        {data?.results
          ?.filter(x => x[filterId] !== parentId)
          ?.slice(0, 7)
          ?.map((result, i) => (
            <CollectionTile
              coverImage={result.cover?.assetLocation ?? defaultTile}
              size={size}
              title={result.friendlyName ?? (result.scientificNames?.[0] || '')}
              to={to?.(result[filterId]) ?? ''}
              key={i}
            />
          ))}
        <SeeAllTile name={title} size="small" to={seeAllTo ?? '#'} />
      </>
    );
  }

  return <div className={styles.noResults}>No Results</div>;
};
