import { MagnifyingGlass } from '@phosphor-icons/react';
import { routes, testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { BannerBackground } from './bannerBackground';

import styles from './homeBanner.module.scss';

export const HomeBanner: React.FC = () => {
  const { arkive } = useDictionary('en');
  return (
    <div data-testid={testIds.authenticated.components.banner('container')} className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <div data-testid={testIds.authenticated.components.banner('background')} className={styles.background}>
            <BannerBackground />
          </div>
          <div data-testid={testIds.authenticated.components.banner('content')} className={styles.content}>
            <div className={styles.spacer} />
            <div data-testid={testIds.authenticated.components.banner('information')} className={styles.information}>
              <h1>{arkive.unauthenticated.views.homeBanner.title}</h1>
              <h3>{arkive.unauthenticated.views.homeBanner.subTitle}</h3>
              <div>{arkive.unauthenticated.views.homeBanner.description}</div>
            </div>
            <div className={styles.spacer} />
            <Link to={routes.authenticated.arkive.explore()} state={{ prevRoute: window.location.pathname }}>
              <div role="button" className={styles.fauxSearchButton}>
                <MagnifyingGlass />
                <div className={styles.search}>Search</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
