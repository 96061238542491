import { ArrowLeft } from '@phosphor-icons/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './backButton.module.scss';

export const BackButton: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(-1)}
      onKeyDown={e => (e.key === 'backspace' ? navigate(-1) : null)}
      role="button"
      tabIndex={0}
      className={styles.backButtonContainer}
    >
      <ArrowLeft /> <div className={styles.back}>back</div>
    </div>
  );
};
