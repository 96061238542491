import { Tooltip } from '@rocketmakers/armstrong';
import { capitalizeFirstLetter, routes, useDictionary } from '@wildscreen/core/src/core';
import { taxonKeyArray } from '@wildscreen/core/src/core/taxonomy';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { apiHooks } from '../../../../api';
import { mapTargetPropertyForQuery, TTargetProperty } from '../../../../helpers/targetProperty';

import styles from './podTag.module.scss';

interface IPodTagProps {
  type: string;
  content: string;
  onClick?: () => void;
}

export const PodTag: React.FC<IPodTagProps> = ({ type, content, onClick }) => {
  const { arkive } = useDictionary('en');

  const [to, setTo] = React.useState<string>('#');
  const [getClassification] = apiHooks.ark.arkClassificationSearchPost.useMutation();

  React.useEffect(() => {
    if (content === '...') {
      return;
    }

    const getWildscreenId = async () => {
      if (taxonKeyArray.includes(type)) {
        const data = await getClassification({
          classificationSearchQuery: {
            from: 0,
            size: 100,
            jitter: false,
            [type]: content,
          },
        });
        const foundTag = data?.data?.results?.find(
          x => x[type] === content && x.taxonRank === capitalizeFirstLetter(type)
        );
        if (foundTag?.wildscreenId) {
          setTo(routes.authenticated.arkive.taxonomy({ wildscreenId: data?.data?.results?.[0]?.wildscreenId }));
        } else {
          setTo('#');
        }
      } else {
        const mappedType = mapTargetPropertyForQuery(type as TTargetProperty);
        setTo(
          routes.authenticated.arkive.exploreClassification({
            classificationProperty: mappedType,
            classificationSearchTerm: content.replace('/', '%2F'),
          })
        );
      }
    };
    getWildscreenId();
  }, [type, content, getClassification]);

  if (!onClick && to !== '#') {
    return (
      <Link data-type={type} data-can-click={true} className={styles.podTag} to={to}>
        {content}
      </Link>
    );
  }

  if (to === '#') {
    return (
      <Tooltip
        delay={250}
        content={arkive.unauthenticated.views.navigation.notPublishedYet(content)}
        showArrow
        side="bottom"
      >
        <div
          role="button"
          onKeyDown={e => e.key === 'o' && onClick?.()}
          onClick={() => onClick?.()}
          data-can-click={false}
          data-type={type}
          className={styles.podTag}
          tabIndex={0}
        >
          {content}
        </div>
      </Tooltip>
    );
  }

  return (
    <div
      role="button"
      onKeyDown={e => e.key === 'o' && onClick?.()}
      onClick={() => onClick?.()}
      data-can-click={true}
      data-type={type}
      className={styles.podTag}
      tabIndex={0}
    >
      {content}
    </div>
  );
};
