import { InstagramLogo, TiktokLogo, YoutubeLogo } from '@phosphor-icons/react';
import { testIds } from '@wildscreen/core/src/core';
import * as React from 'react';

import styles from './socials.module.scss';

export const Socials: React.FC = () => {
  return (
    <div className={styles.socials}>
      <a
        data-testid={testIds.authenticated.components.socials('instagram')}
        target="__blank"
        href="https://www.instagram.com/wildscreenorg"
      >
        <InstagramLogo weight="fill" />
      </a>
      <a
        data-testid={testIds.authenticated.components.socials('x')}
        target="__blank"
        href="https://twitter.com/WildscreenFest"
      >
        <svg viewBox="0 0 39 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.816768 0.248047L15.5266 19.9165L0.723877 35.9078H4.05538L17.0152 21.9072L27.4863 35.9078H38.8235L23.286 15.133L37.0643 0.248047H33.7328L21.7975 13.1423L12.154 0.248047H0.816768ZM5.71599 2.70204H10.9243L33.9235 33.4534H28.7152L5.71599 2.70204Z"
            fill="white"
          />
        </svg>
      </a>
      <a
        data-testid={testIds.authenticated.components.socials('youtube')}
        target="__blank"
        href="https://www.youtube.com/channel/UCW3TocMGhNAJ9jkYnq9pY0A"
      >
        <YoutubeLogo weight="fill" />
      </a>
      <a
        data-testid={testIds.authenticated.components.socials('tiktok')}
        target="__blank"
        href="https://www.tiktok.com/@wildscreen"
      >
        <TiktokLogo weight="fill" />
      </a>
    </div>
  );
};
