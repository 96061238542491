import { ArrowRight } from '@phosphor-icons/react';
import { LibraryCredit as LibraryCreditObject } from '@wildscreen/api/src/apiClients';
import { testIds, useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { addMaxWidthToImageLocation } from '../../helpers/imageHelpers';

import styles from './libraryCredit.module.scss';

export interface ILibraryCreditProps {
  data?: Omit<LibraryCreditObject, 'creationTimestamp' | 'creationUserId' | 'updatedUserId' | 'updatedTimestamp'>;
}

export const LibraryCredit: React.FC<ILibraryCreditProps> = ({ data }) => {
  const { admin } = useDictionary('en');
  const [hasImage, setHasImage] = React.useState(!!data?.libraryCreditLocation);
  const imageUrlMaxWidth = addMaxWidthToImageLocation(data?.libraryCreditLocation, 102);

  return (
    <div data-testid={testIds.authenticated.components.libraryCredit('view')} className={styles.container}>
      <div className={styles.imageAndName}>
        <div data-testid={testIds.authenticated.components.libraryCredit('image')} className={styles.image}>
          {hasImage && <img onError={() => setHasImage(false)} src={imageUrlMaxWidth} alt={data?.name} />}
        </div>
        <div data-testid={testIds.authenticated.components.libraryCredit('name')} className={styles.name}>
          {data?.name}
        </div>
      </div>
      <Link
        data-testid={testIds.authenticated.components.libraryCredit('link')}
        target="_blank"
        to={data?.url ?? '#'}
        className={styles.link}
      >
        {admin.authenticated.views.libraryCredit.findOutMore} <ArrowRight />
      </Link>
    </div>
  );
};
