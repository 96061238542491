import { routes, testIds } from '@wildscreen/core/src/core';
import { HeaderLogo } from '@wildscreen/ui-components/src/components/logos/header/header';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { useHeaderContext } from '../../../../contexts/header';
import { Search } from '../../../search';
import { Socials } from '../../../socials/socials';
import { FooterLogo } from '../../footer/components/logo';
import { Rocketmakers } from '../../footer/components/rocketmakers';
import { Background } from '../common/background';
import { NavigationLinks } from '../common/navigation';
import { LeftHeader } from './leftHeader';
import { RightHeader } from './rightHeader';

import styles from './header.module.scss';

export const MobileHeader: React.FC = () => {
  const { isMenuOpen, isScrolled } = useHeaderContext();

  return (
    <>
      <header data-testid={testIds.authenticated.components.header.arkive('view')} className={styles.container}>
        <LeftHeader />
        <HeaderLogo color={isScrolled || isMenuOpen ? 'hide' : 'white'} />
        <RightHeader />
      </header>
      <div data-is-menu-open={isMenuOpen} className={styles.menu}>
        <Link to={routes.authenticated.arkive.root()} state={{ prevRoute: window.location.pathname }}>
          <FooterLogo hideCopyright />
        </Link>
        <Search onFoundResults={() => null} />
        <NavigationLinks />
        <Socials />
        <div className={styles.dividerContainer}>
          <div className={styles.divider}></div>
          <Rocketmakers />
        </div>
        <Background />
      </div>
    </>
  );
};
