import { LibraryCredit as LibraryCreditObject, SearchAsset } from '@wildscreen/api/src/apiClients';
import { testIds } from '@wildscreen/core/src/core/testIds';
import * as React from 'react';

import { useRenderAsset } from '../../../../hooks/useRenderAsset';
import { LibraryCredit } from '../../../libraryCredit';
import { AssetCredit, TCreditPosition } from '../assetCredit';

import styles from './pod.module.scss';

type TPodType = 'asset' | 'taxonomy';

export type TPodProps = React.PropsWithChildren<
  {
    type: TPodType;
    heading: string;
    secondHeading?: string;
    subHeading?: string;
    description?: string;
    tags?: React.ReactNode;
    assetCredit?: string;
    creditPosition?: TCreditPosition;
    libraryCredit?: LibraryCreditObject;
    assetMaxWidth: number;
  } & Pick<SearchAsset, 'assetLocation' | 'isVideo' | 'title'>
>;

export const Pod: React.FC<TPodProps> = ({
  heading,
  secondHeading,
  subHeading,
  description,
  tags,
  assetCredit,
  title,
  assetLocation,
  assetMaxWidth,
  type = 'asset',
  isVideo,
  children,
  libraryCredit,
  creditPosition = 'in-image-bottom',
}) => {
  const asset = useRenderAsset(assetMaxWidth, isVideo, title, assetLocation);

  return (
    <div data-testid={testIds.authenticated.components.pod(type, heading, 'container')} className={styles.podContainer}>
      <div data-testid={testIds.authenticated.components.pod(type, heading, 'pod')} className={styles.pod}>
        <div data-testid={testIds.authenticated.components.pod(type, heading, 'asset')} className={styles.asset}>
          {asset}
          {['in-image-bottom', 'in-image-top'].includes(creditPosition) && assetCredit && (
            <AssetCredit position={creditPosition} credit={assetCredit} />
          )}
        </div>
        <div data-testid={testIds.authenticated.components.pod(type, heading, 'content')} className={styles.content}>
          <h2 data-testid={testIds.authenticated.components.pod(type, heading, 'heading')}>{heading}</h2>
          {secondHeading && (
            <h2 data-testid={testIds.authenticated.components.pod(type, secondHeading, 'second-heading')}>
              {secondHeading}
            </h2>
          )}
          {subHeading && (
            <h3 data-testid={testIds.authenticated.components.pod(type, heading, 'sub-heading')}>{subHeading}</h3>
          )}
          {tags && (
            <div data-testid={testIds.authenticated.components.pod(type, heading, 'tags')} className={styles.tags}>
              {tags}
            </div>
          )}
          <div
            data-testid={testIds.authenticated.components.pod(type, heading, 'description')}
            className={styles.description}
          >
            {description}
          </div>
          {assetCredit && creditPosition === 'underneath' && (
            <AssetCredit position={creditPosition} credit={assetCredit} />
          )}
          {libraryCredit && <LibraryCredit data={libraryCredit} />}
        </div>
        {children}
      </div>
    </div>
  );
};
