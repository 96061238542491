import { routes } from '@wildscreen/core/src/core';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useHeaderContext } from '../contexts/header';

export function useShouldHideHeaderComponents() {
  const { isSearchOpen } = useHeaderContext();
  const { pathname } = useLocation();
  return React.useMemo(() => {
    if (pathname === routes.authenticated.arkive.explore()) {
      return true;
    }

    if (isSearchOpen) {
      return true;
    }

    return false;
  }, [isSearchOpen, pathname]);
}
