import * as React from 'react';

import { useContainerSize } from '../../hooks/useContainerSize';
import { BaseCollectionRow, IBaseCollectionTilesProps } from './baseCollectionRow';

import styles from './baseCollectionTiles.module.scss';

export const BaseCollectionTiles: React.FC<IBaseCollectionTilesProps> = ({
  title,
  isFetching,
  data,
  to,
  parentId,
  filterId,
  seeAllTo,
  size = 'small',
}) => {
  const { contentRef, canScroll } = useContainerSize(size); // The small Collection Tile is 107px width, so 7 tiles is 749px and 8 is 856px and width padding is 16px

  return (
    <div ref={contentRef} className={styles.container}>
      <h3>{title}</h3>
      <div data-scroll={canScroll} className={styles.row}>
        <BaseCollectionRow
          title={title}
          isFetching={isFetching}
          data={data}
          to={to}
          parentId={parentId}
          filterId={filterId}
          size={size}
          seeAllTo={seeAllTo}
        />
      </div>
    </div>
  );
};
