import { dictionary, routes } from '@wildscreen/core/src/core';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { LocalStorageKeys } from '../localStorageKeys';
import { useGoogleAnalyticsContext } from './googleAnalytics';

export interface ICookieOption {
  title: string;
  description: string;
  isDisabled: boolean;
  default: boolean;
  onUpdate?: (value: boolean) => void;
}

export interface ICookieOptions {
  essential: ICookieOption;
  analytics: ICookieOption;
}

export type CookiePreferences = {
  [p in keyof ICookieOptions]: boolean;
};

// Update when you want users to update their preferences
const version = 'v1';

interface ICookiePreferencesContext {
  isOpen: boolean;
  options: ICookieOptions;
  open: () => void;
  saveAndClose: (preferences: CookiePreferences) => void;
}

export const CookiePreferencesContext = React.createContext<ICookiePreferencesContext>({
  isOpen: false,
  options: undefined as unknown as ICookieOptions,
  open: () => {},
  saveAndClose: () => {},
});

export const CookiePreferencesContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();
  const { isEnabled, setEnabled } = useGoogleAnalyticsContext();

  React.useEffect(() => {
    const acceptedVersion = localStorage.getItem(LocalStorageKeys.CookiePreferenceAcceptVersion);
    const versionNotAccepted = !acceptedVersion || acceptedVersion !== version;

    // We don't want the cookie banner covering the privacy policy
    setIsOpen(versionNotAccepted && !location.pathname.endsWith(routes.authenticated.arkive.privacyPolicy()));
  }, [setIsOpen, location]);

  const options: ICookieOptions = React.useMemo(
    () => ({
      essential: {
        title: dictionary.en.arkive.unauthenticated.views.cookieBanner.preferences.essential.title,
        description: dictionary.en.arkive.unauthenticated.views.cookieBanner.preferences.essential.description,
        isDisabled: true,
        default: true,
      },
      analytics: {
        title: dictionary.en.arkive.unauthenticated.views.cookieBanner.preferences.analytics.title,
        description: dictionary.en.arkive.unauthenticated.views.cookieBanner.preferences.analytics.description,
        isDisabled: false,
        default: isEnabled,
        onUpdate: (value: boolean) => setEnabled(value),
      },
    }),
    [isEnabled, setEnabled]
  );

  const open = React.useCallback(() => setIsOpen(true), [setIsOpen]);

  const saveAndClose = React.useCallback(
    (preferences: CookiePreferences) => {
      const preferenceKeys = Object.keys(preferences) as (keyof CookiePreferences)[];
      for (const key of preferenceKeys) {
        options[key].onUpdate?.(preferences[key]);
      }

      localStorage.setItem(LocalStorageKeys.CookiePreferenceAcceptVersion, version);
      setIsOpen(false);
    },
    [options, setIsOpen]
  );

  return (
    <CookiePreferencesContext.Provider value={{ isOpen, options, open, saveAndClose }}>
      {children}
    </CookiePreferencesContext.Provider>
  );
};

export function useCookiePreferencesContext() {
  const ctx = React.useContext(CookiePreferencesContext);
  if (!ctx) {
    throw new Error('No Cookie Preferences Context');
  }
  return ctx;
}
