import { routes } from '@wildscreen/core/src/core';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

interface IHeaderContext {
  showSearch: boolean;
  setShowSearch: (value: boolean) => void;
  isSearchOpen: boolean;
  setIsSearchOpen: (value: boolean) => void;
  isMenuOpen: boolean;
  setIsMenuOpen: (value: boolean) => void;
  hideBackButton: boolean;
  setHideBackButton: (value: boolean) => void;
  isScrolled: boolean;
  setIsScrolled: (value: boolean) => void;
}

export const HeaderContext = React.createContext<IHeaderContext>({
  setShowSearch: () => {},
  showSearch: false,
  setIsSearchOpen: () => {},
  isSearchOpen: false,
  setIsMenuOpen: () => {},
  isMenuOpen: false,
  setHideBackButton: () => {},
  hideBackButton: false,
  setIsScrolled: () => {},
  isScrolled: false,
});

export const HeaderContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [showSearch, setShowSearch] = React.useState(false);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [hideBackButton, setHideBackButton] = React.useState(false);
  const [isScrolled, setIsScrolled] = React.useState(false);

  const location = useLocation();
  const [currentLocation, setCurrentLocation] = React.useState<string>(location.pathname);

  React.useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Use history.listen to listen for route changes
  React.useEffect(() => {
    if (isMenuOpen && location.pathname !== currentLocation) {
      setCurrentLocation(location.pathname);
      setIsMenuOpen(false);
    }
  }, [currentLocation, isMenuOpen, location, setIsMenuOpen]);

  React.useEffect(() => {
    if (location.pathname === routes.authenticated.arkive.root()) {
      setHideBackButton(true);
    } else {
      setHideBackButton(false);
    }
  }, [location.pathname]);

  return (
    <HeaderContext.Provider
      value={{
        showSearch,
        setShowSearch,
        isSearchOpen,
        setIsSearchOpen,
        isMenuOpen,
        setIsMenuOpen,
        hideBackButton,
        setHideBackButton,
        isScrolled,
        setIsScrolled,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export function useHeaderContext() {
  const ctx = React.useContext(HeaderContext);
  if (!ctx) {
    throw new Error('No Header Context');
  }
  return ctx;
}

export function useShowSearch() {
  const { setShowSearch } = useHeaderContext();
  React.useLayoutEffect(() => {
    setShowSearch?.(true);
  }, [setShowSearch]);
}

export function useHideSearch() {
  const { setShowSearch } = useHeaderContext();
  React.useLayoutEffect(() => {
    setShowSearch?.(false);
  }, [setShowSearch]);
}
