import * as Sentry from '@sentry/react';
import { routes } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useCookiePreferencesContext } from '../../contexts/cookiePreferences';
import { useHeaderContext } from '../../contexts/header';
import { SentryErrorPage } from '../../views/sentryErrorPage';
import { CookieBanner } from '../cookieBanner';
import { Footer } from './footer';
import { Header, MobileHeader } from './header';

import styles from './shell.module.scss';

export const Shell: React.FC = () => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const { isSearchOpen } = useHeaderContext();
  const { isOpen, options, saveAndClose } = useCookiePreferencesContext();

  const [isSentryError, setIsSentryError] = React.useState<boolean>(false);
  const [currentLocation, setCurrentLocation] = React.useState<string>(location.pathname);

  React.useEffect(() => {
    if (location.pathname !== currentLocation) {
      setCurrentLocation(location.pathname);
      if (isSentryError) {
        setIsSentryError(false);
      }
    }
  }, [currentLocation, location, isSentryError, setIsSentryError]);

  const showBackgroundImage = location.pathname === routes.authenticated.arkive.error404() || isSentryError;
  return (
    <div data-is-search-open={isSearchOpen} data-background-image={showBackgroundImage} className={styles.shell}>
      {isMobile ? <MobileHeader /> : <Header />}
      <main>
        <Sentry.ErrorBoundary
          onError={() => {
            setIsSentryError(true);
          }}
          fallback={({ error, eventId, componentStack, resetError }) => (
            <SentryErrorPage error={error} eventId={eventId} componentStack={componentStack} resetError={resetError} />
          )}
        >
          <Outlet />
        </Sentry.ErrorBoundary>
      </main>
      <Footer />
      <CookieBanner isOpen={isOpen} options={options} saveAndClose={saveAndClose} />
    </div>
  );
};
