import { List, MagnifyingGlass, X } from '@phosphor-icons/react';
import { routes, testIds } from '@wildscreen/core/src/core';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useHeaderContext } from '../../../../contexts/header';

import styles from './header.module.scss';

export const RightHeader: React.FC = () => {
  const { isSearchOpen, setIsSearchOpen, isMenuOpen, setIsMenuOpen, showSearch } = useHeaderContext();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const openMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSearchOpen(false);
  };

  const onClick = React.useCallback(() => {
    if (pathname === routes.authenticated.arkive.explore() && isSearchOpen) {
      navigate(state.prevRoute, { state: { prevRoute: window.location.pathname } });
      setIsSearchOpen(false);
    }

    if (!isSearchOpen) {
      setIsSearchOpen(true);
      navigate(routes.authenticated.arkive.explore(), { state: { prevRoute: pathname } });
    } else {
      setIsSearchOpen(false);
    }
  }, [isSearchOpen, navigate, pathname, setIsSearchOpen, state]);

  return (
    <div
      data-show-search={showSearch}
      data-testid={testIds.authenticated.components.header.arkive('right-container')}
      className={styles.rightHeaderContainer}
    >
      {showSearch && (
        <div
          data-testid={testIds.authenticated.components.header.arkive(`search-${isSearchOpen ? 'open' : 'closed'}`)}
          className={styles.icon}
          role="button"
          onKeyDown={e => e.key === 's' && onClick()}
          onClick={onClick}
          tabIndex={0}
        >
          {isSearchOpen ? <X weight="bold" /> : <MagnifyingGlass weight="bold" />}
        </div>
      )}
      <div
        data-testid={testIds.authenticated.components.header.arkive(`menu-${isMenuOpen ? 'open' : 'closed'}`)}
        className={styles.icon}
        role="button"
        onKeyDown={e => e.key === 'm' && openMenu()}
        onClick={() => openMenu()}
        tabIndex={0}
      >
        {isMenuOpen ? <X weight="bold" /> : <List />}
      </div>
    </div>
  );
};
