import { useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';

import { UserAgreement } from '../../components/userAgreement';

export const PrivacyPolicy: React.FC = () => {
  const { arkive } = useDictionary('en');
  const { heading, content } = arkive.unauthenticated.views.privacyPolicy;

  return <UserAgreement heading={heading}>{content()}</UserAgreement>;
};
