import { testIds } from '@wildscreen/core/src/core';
import * as React from 'react';

import styles from './dataSource.module.scss';

export type TFooterDataSource = { source: string; content: string; link: string };

const sources: Array<TFooterDataSource> = [
  {
    source: 'IUCN Red List Data Source',
    content: 'IUCN 2022. IUCN Red List of Threatened Species. Version 2022-2',
    link: 'https://www.iucnredlist.org/',
  },
  {
    source: 'Taxonomy Data Source',
    content: 'GBIF 2022. GBIF Taxonomy example citation. Version 2022-2',
    link: 'https://www.gbif.org/',
  },
];

const FooterDataSource: React.FC<TFooterDataSource> = ({ source, content, link }) => {
  return (
    <div className={styles.footerDataSource}>
      <span>{source}:</span>
      <span>{content}</span>
      <a href={link}>{link}</a>
    </div>
  );
};

export const FooterDataSources: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div>
      <div className={styles.divider} />
      {children}
      <div data-testid={testIds.authenticated.components.footer('data-sources')} className={styles.dataSources}>
        {sources.map((source, index) => (
          <FooterDataSource key={index} {...source} />
        ))}
      </div>
    </div>
  );
};
