import { TaxonRank } from '@wildscreen/api/src/apiClients';
import { TAsset } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { BackButton } from '../../components/backButton';
import { Gallery } from '../../components/gallery';
import { AssetPod } from '../../components/pod/assetPod';
import { RelatedAssets } from '../../components/related/relatedAssets';
import { TabController } from '../../components/tabController';

import styles from './asset.module.scss';

export const AssetView: React.FC = () => {
  const { assetId } = useParams<TAsset>();
  const isMobile = useIsMobile();

  const [{ data: assetData }] = apiHooks.ark.arkAssetDocumentIdGet.useQuery({
    parameters: {
      id: assetId,
    },
    cacheKey: 'id',
  });

  return (
    <div className={styles.container}>
      {!isMobile && <BackButton />}
      <div className={styles.content}>
        <AssetPod data={assetData?.data} />
        <TabController
          related={<RelatedAssets assetId={assetId} searchData={assetData?.data} />}
          gallery={
            <Gallery
              maxImageWidth={444}
              searchData={assetData?.data}
              wildscreenId={assetId}
              overrideTaxonData={{
                currentTaxonRank: TaxonRank.Species.toLowerCase(),
                currentTaxonValue: assetData?.data?.inheritance?.species?.values?.[0] ?? '',
              }}
            />
          }
        />
      </div>
    </div>
  );
};
