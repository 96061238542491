import * as React from 'react';

export function useIsMobile() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 767);

  React.useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
}
