import { AssetListProperty } from '@wildscreen/api/src/apiClients';
import { routes } from '@wildscreen/core/src/core';
import { shuffleArray } from '@wildscreen/core/src/core/helpers';
import * as React from 'react';

import { apiHooks } from '../../api';
import { useContainerSize } from '../../hooks/useContainerSize';
import { CollectionTile } from '../collectionTile';
import { SeeAllTile } from '../seeAllTile';

import defaultTile from '@wildscreen/ui-components/src/assets/pngs/defaultTile.png';

import styles from './assetCollectionTiles.module.scss';

export interface IassetCollectionTilesProps {
  property: AssetListProperty;
}

export const AssetCollectionTiles: React.FC<IassetCollectionTilesProps> = ({ property }) => {
  const [{ data, isFetching }] = apiHooks.data.dataPropertiesAssetTargetPropertyGet.useQuery({
    parameters: {
      targetProperty: property,
    },
    cacheKey: p => `${p?.targetProperty}`,
  });
  const { contentRef, canScroll } = useContainerSize(); // The small Collection Tile is 107px width, so 7 tiles is 749px and 8 is 856px and width padding is 16px

  const shuffledArray = React.useMemo(() => shuffleArray(data?.data?.tags || []), [data?.data?.tags]);

  return (
    <div ref={contentRef} className={styles.container}>
      <h3>{property}</h3>
      <div data-scroll={canScroll} className={styles.row}>
        {isFetching
          ? Array.from({ length: 7 }).map((_, i) => {
              return <CollectionTile isLoading={true} coverImage={''} size="small" title="" to="/" key={i} />;
            })
          : shuffledArray.slice(0, 7).map((result, i) => (
              <CollectionTile
                coverImage={result.coverImageLocation ?? defaultTile}
                size="small"
                title={result.name ?? ''}
                to={routes.authenticated.arkive.exploreAsset({
                  assetProperty: property,
                  assetSearchTerm: result.name,
                })}
                key={i}
              />
            ))}
        <SeeAllTile
          name={property}
          size="small"
          to={routes.authenticated.arkive.assetProperty({
            targetProperty: property,
          })}
        />
      </div>
    </div>
  );
};
