import { TClassification } from '@wildscreen/core/src/core';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../../api';

import styles from './classification.module.scss';

export const ClassificationView: React.FC = () => {
  const { classificationId } = useParams<TClassification>();

  const [{ data, isFetching }] = apiHooks.ark.arkClassificationDocumentIdGet.useQuery({
    parameters: {
      id: classificationId,
    },
    cacheKey: 'id',
  });

  return (
    <div className={styles.container}>
      <h2>{classificationId}</h2>
      {isFetching ? 'Loading' : <pre>{JSON.stringify(data, null, 2)}</pre>}
    </div>
  );
};
