import {
  AssetListProperty,
  AssetSearchQuery,
  ClassificationListProperty,
  ClassificationSearchQuery,
} from '@wildscreen/api/src/apiClients';

type TMappedTargetProperty = keyof AssetSearchQuery | keyof ClassificationSearchQuery;

type LowercaseFirstChar<S extends string> = S extends `${infer First}${infer Rest}` ? `${Lowercase<First>}${Rest}` : S;

export type TTargetProperty =
  | ClassificationListProperty
  | AssetListProperty
  | LowercaseFirstChar<ClassificationListProperty>
  | LowercaseFirstChar<AssetListProperty>
  | 'LandRegion'
  | 'landRegion'
  | 'habitat';

export function mapTargetPropertyForQuery(targetProperty?: TTargetProperty): TMappedTargetProperty {
  if (!targetProperty) {
    throw new Error('Target property is required');
  }

  switch (targetProperty) {
    case 'Adaptations':
    case 'adaptations':
      return 'adaptation';
    case 'BiologicalThemes':
    case 'biologicalThemes':
      return 'biologicalTheme';
    case 'Habitats':
    case 'habitats':
    case 'habitat':
      return 'habitat';
    case 'LandRegions':
    case 'landRegions':
    case 'LandRegion':
    case 'landRegion':
      return 'landRegion';
    case 'Threats':
    case 'threats':
      return 'threat';
    case 'Actions':
    case 'actions':
      return 'action';
    case 'LifeStages':
    case 'lifeStages':
      return 'lifeStages';
    case 'Behaviours':
    case 'behaviours':
      return 'behaviour';
    case 'GeneralContentDescriptors':
    case 'generalContentDescriptors':
      return 'generalContentDescriptors';
    default:
      throw new Error('Unknown target property');
  }
}
