import { useDictionary } from '@wildscreen/core/src/core';
import * as React from 'react';

import { ErrorPage } from '../../components/errorPage';

export interface ISentryErrorPageProps {
  error: Error;
  eventId: string;
  componentStack: string;
  resetError: () => void;
}

export const SentryErrorPage: React.FC<ISentryErrorPageProps> = ({ error, eventId, componentStack, resetError }) => {
  const { arkive } = useDictionary('en');
  const { title, subtitle, descriptionDev, descriptionProd, resetButtonCaption } =
    arkive.unauthenticated.views.sentryErrorPage;

  const redactedEnvs: Environment[] = ['prod'];
  const isRedacted = redactedEnvs.includes(APP_CONFIG.environment);
  const description = isRedacted ? descriptionProd(eventId) : descriptionDev;
  const stackTrace = isRedacted ? undefined : `${error.message}\n${componentStack}`;

  return (
    <ErrorPage
      title={title}
      subtitle={subtitle}
      description={description}
      stackTrace={stackTrace}
      resetButtonCaption={resetButtonCaption}
      resetButtonOnClick={resetError}
    />
  );
};
