import { ClassificationListProperty, TaxonRank } from '@wildscreen/api/src/apiClients';
import { routes, useDictionary } from '@wildscreen/core/src/core';
import { NoResults } from '@wildscreen/ui-components/src/components/noResults/noResults';
import * as React from 'react';

import { apiHooks } from '../../api';
import { mapTargetPropertyForQuery } from '../../helpers/targetProperty';
import { CollectionTile } from '../collectionTile';

import defaultTile from '@wildscreen/ui-components/src/assets/pngs/defaultTile.png';

import styles from './shared.module.scss';

export interface ISpecies {
  classificationSearchTerm?: string;
  targetProperty?: ClassificationListProperty;
}

export const Species: React.FC<ISpecies> = ({ classificationSearchTerm, targetProperty }) => {
  const { general } = useDictionary('en');
  const [fetchClassifications, { data, isFetching }] = apiHooks.ark.arkClassificationSearchPost.useMutation();

  React.useEffect(() => {
    if (classificationSearchTerm && targetProperty) {
      fetchClassifications({
        classificationSearchQuery: {
          from: 0,
          jitter: true,
          size: 200,
          taxonRank: TaxonRank.Species,
          [mapTargetPropertyForQuery(targetProperty) as string]: classificationSearchTerm,
        },
      });
    }
  }, [classificationSearchTerm, fetchClassifications, targetProperty]);

  const noResults = data?.data.results?.length === 0 && !isFetching;
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{general.species}</h3>
      <div className={styles.grid} data-no-results={noResults}>
        {data?.data?.results?.map(x => (
          <CollectionTile
            key={x.wildscreenId}
            coverImage={x.cover?.assetLocation ?? defaultTile}
            size="large"
            title={x.friendlyName ?? (x.scientificNames?.[0] || '')}
            to={routes.authenticated.arkive.taxonomy({ wildscreenId: x.wildscreenId })}
          />
        ))}
        {noResults && <NoResults />}
      </div>
    </div>
  );
};
