import { routes, testIds } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import { useIsSmallScreen } from '@wildscreen/core/src/hooks/useIsSmallScreen';
import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useHeaderContext } from '../../../../contexts/header';
import { useShouldHideHeaderComponents } from '../../../../hooks/useShouldHideHeaderComponents';
import { NavigationLinks } from '../common/navigation';
import { SearchButton } from '../common/searchButton';

import headerLogo from '@wildscreen/ui-components/src/assets/svgs/headerLogo.svg';

import styles from './header.module.scss';

export const Header: React.FC = () => {
  const { showSearch, setIsSearchOpen, isSearchOpen } = useHeaderContext();
  const location = useLocation();
  const isMobile = useIsMobile();
  const isSmallScreen = useIsSmallScreen();

  const canShowSearch = React.useMemo(() => {
    if (isMobile) {
      return showSearch;
    }
    return showSearch;
  }, [isMobile, showSearch]);

  React.useEffect(() => {
    if (location.state?.prevRoute === routes.authenticated.arkive.explore()) {
      setIsSearchOpen(false);
    }
  }, [location, setIsSearchOpen]);

  const hideHeaderElements = useShouldHideHeaderComponents();

  return (
    <header
      data-is-search-open={isSearchOpen}
      data-testid={testIds.authenticated.components.header.arkive('view')}
      className={styles.container}
    >
      <div className={styles.headerContent}>
        <div className={styles.header} />
        <div data-is-search-open={hideHeaderElements} className={styles.header}>
          <Link to={routes.authenticated.arkive.root()} state={{ prevRoute: window.location.pathname }}>
            <img
              data-is-search-open={hideHeaderElements}
              data-testid={testIds.authenticated.components.header.arkive('logo')}
              src={headerLogo}
              alt="Wildscreen Header Logo"
            />
          </Link>
          {(!isSmallScreen && <NavigationLinks />) || <div className={styles.spacer} />}
          {canShowSearch && <SearchButton />}
        </div>
        <div className={styles.header} />
      </div>
    </header>
  );
};
