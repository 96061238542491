import { SearchClassification, TaxonRank } from '@wildscreen/api/src/apiClients';
import { removePluralFromWords } from '@wildscreen/core/src/core';
import { getTagsFromClassification } from '@wildscreen/core/src/core/taxonomy';
import * as React from 'react';

export function useGenerateTaxonomyQuery(
  data?: SearchClassification,
  onlyReturnParentTiles?: boolean,
  addAssetTags?: boolean
) {
  return React.useMemo(() => {
    if (!data) {
      return {};
    }
    const tagsArray = getTagsFromClassification(data);
    let createdObject = {}; // Get the Current TaxonRank and retrieve the current value of the rank

    const currentTaxonRank = data?.taxonRank?.toLowerCase() || '';
    const currentTaxonValue = data[currentTaxonRank];

    if (currentTaxonRank !== TaxonRank.Species.toLowerCase() && !onlyReturnParentTiles) {
      createdObject = {
        ...createdObject,
        [`${currentTaxonRank}:${currentTaxonValue}`]: {
          [currentTaxonRank]: currentTaxonValue,
          jitter: true,
        },
      };
    }

    const tags = ['genus', 'adaptation', 'behaviour', 'habitat', 'regions', 'threats', 'actions'];

    if (addAssetTags) {
      tags.push('biologicalThemes');
      tags.push('lifestages');
    }

    tagsArray.reduce((acc, cur) => {
      const currentTagType = removePluralFromWords(cur.type.toLowerCase());
      const currentTagValue = cur.tag.toLowerCase();
      if (onlyReturnParentTiles && tags.includes(currentTagType)) {
        acc[`${currentTagType}:${currentTagValue}`] = {
          [currentTagType]: currentTagValue,
          jitter: true,
        };
      } else if (!onlyReturnParentTiles) {
        acc[`${currentTagType}:${currentTagValue}`] = {
          [currentTagType]: currentTagValue,
          jitter: true,
        };
      }

      return acc;
    }, createdObject);
    const queryKeys = Object.keys(createdObject)?.filter(x => x.split(':')[0] !== TaxonRank.Species.toLowerCase());
    if (onlyReturnParentTiles) {
      queryKeys.sort((a, b) => {
        if (a.includes('genus')) return -1;
        return a.localeCompare(b);
      });
    }
    return {
      query: createdObject,
      queryKeys,
    };
  }, [addAssetTags, data, onlyReturnParentTiles]);
}
