import { SearchClassification } from '@wildscreen/api/src/apiClients';
import { TTaxonomy } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { apiHooks } from '../../api';
import { BackButton } from '../../components/backButton';
import { Gallery } from '../../components/gallery';
import { TaxonomyPod } from '../../components/pod';
import { Related } from '../../components/related';
import { TabController } from '../../components/tabController';

import styles from './taxonomy.module.scss';

export const TaxonomyView: React.FC = () => {
  const { wildscreenId } = useParams<TTaxonomy>();
  const isMobile = useIsMobile();

  const [{ data }] = apiHooks.ark.arkClassificationDocumentIdGet.useQuery({
    parameters: {
      id: wildscreenId,
    },
    cacheKey: 'id',
  });

  const searchData = data?.data as unknown as SearchClassification;

  return (
    <div className={styles.container}>
      {!isMobile && <BackButton />}
      <div className={styles.content}>
        <TaxonomyPod data={searchData} />
        <TabController
          related={<Related searchData={searchData} wildscreenId={wildscreenId} />}
          gallery={<Gallery searchData={searchData} wildscreenId={wildscreenId} maxImageWidth={444} />}
        />
      </div>
    </div>
  );
};
