import { ClassNameUtils } from '@rocketmakers/armstrong';
import { dictionary, routes, testIds } from '@wildscreen/core/src/core';
import { useIsMobile } from '@wildscreen/core/src/hooks/useIsMobile';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

import { useShouldHideHeaderComponents } from '../../../../hooks/useShouldHideHeaderComponents';

import styles from './navigation.module.scss';

type TLinks = {
  to: string;
  bold?: boolean;
  label: string;
  testId: string;
  type: 'both' | 'mobile-only' | 'desktop-only';
  extraMargin?: boolean;
};

const links: Array<TLinks> = [
  {
    bold: true,
    testId: testIds.authenticated.components.header.arkive('explore-link'),
    to: routes.authenticated.arkive.explore(),
    label: dictionary.en.arkive.unauthenticated.views.navigation.explore,
    type: 'both',
  },
  {
    bold: true,
    testId: testIds.authenticated.components.header.arkive('about-us-link'),
    to: routes.authenticated.arkive.aboutUs(),
    extraMargin: true,
    label: dictionary.en.arkive.unauthenticated.views.navigation.aboutUs,
    type: 'both',
  },
  {
    bold: true,
    testId: testIds.authenticated.components.header.arkive('education-resources-link'),
    to: routes.authenticated.arkive.educationResources(),
    extraMargin: true,
    label: dictionary.en.arkive.unauthenticated.views.navigation.educationResources,
    type: 'both',
  },
  {
    bold: true,
    testId: testIds.authenticated.components.header.arkive('wildscreen-link'),
    to: 'https://wildscreen.org',
    extraMargin: true,
    label: dictionary.en.arkive.unauthenticated.views.navigation.wildscreen,
    type: 'mobile-only',
  },
  {
    bold: false,
    extraMargin: true,
    testId: testIds.authenticated.components.header.arkive('terms-and-conditions-link'),
    to: routes.authenticated.arkive.termsAndConditions(),
    label: dictionary.en.arkive.unauthenticated.views.navigation.termsAndConditions,
    type: 'mobile-only',
  },
  {
    bold: false,
    testId: testIds.authenticated.components.header.arkive('privacy-policy-link'),
    to: routes.authenticated.arkive.privacyPolicy(),
    label: dictionary.en.arkive.unauthenticated.views.navigation.privacyPolicy,
    type: 'mobile-only',
  },
];

export const NavigationLinks: React.FC = () => {
  const isMobile = useIsMobile();
  const hideHeaderComponents = useShouldHideHeaderComponents();

  const isMobileLinks = links.filter(x => x.type === 'mobile-only' || x.type === 'both');
  const isDesktopLinks = links.filter(x => x.type === 'desktop-only' || x.type === 'both');
  const newLinks = isMobile ? isMobileLinks : isDesktopLinks;

  if (hideHeaderComponents) {
    return <div style={{ flex: 1 }} />;
  }

  return (
    <nav className={styles.links}>
      {newLinks.map((link, index) => (
        <NavLink
          className={({ isActive }) => ClassNameUtils.concat(isActive && styles.active)}
          key={link.label + index}
          data-bold={link.bold}
          data-testid={link.testId}
          to={link.to}
          state={{ prevRoute: window.location.pathname }}
        >
          {link.label}
        </NavLink>
      ))}
    </nav>
  );
};
