import * as React from 'react';

import { useHideSearch } from '../../contexts/header';

import styles from './userAgreement.module.scss';

export interface IUserAgreementProps extends React.PropsWithChildren {
  heading: string;
}

export const UserAgreement: React.FC<IUserAgreementProps> = ({ heading, children }) => {
  useHideSearch();

  return (
    <div className={styles.container}>
      <h1>{heading}</h1>
      {children}
    </div>
  );
};
