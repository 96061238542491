import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { routes, testIds } from '@wildscreen/core/src/core';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useHeaderContext } from '../../../../contexts/header';

import styles from './searchButton.module.scss';

export const SearchButton: React.FC = () => {
  const { showSearch, isSearchOpen, setIsSearchOpen } = useHeaderContext();
  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const onClick = React.useCallback(() => {
    if (isSearchOpen) {
      navigate(state.prevRoute ?? -1);
      setIsSearchOpen(false);
    }

    if (!isSearchOpen) {
      setIsSearchOpen(true);
      navigate(routes.authenticated.arkive.explore(), { state: { prevRoute: pathname } });
    } else {
      setIsSearchOpen(false);
    }
  }, [isSearchOpen, navigate, pathname, setIsSearchOpen, state]);

  return (
    <div
      data-is-open={isSearchOpen}
      data-show-search={showSearch}
      data-testid={testIds.authenticated.components.header.arkive('right-container')}
      className={styles.searchContainer}
    >
      <div
        data-testid={testIds.authenticated.components.header.arkive(`search-${isSearchOpen ? 'open' : 'closed'}`)}
        className={styles.icon}
        role="button"
        onKeyDown={e => e.key === 's' && onClick()}
        tabIndex={0}
        onClick={onClick}
      >
        {isSearchOpen ? <X weight="bold" /> : <MagnifyingGlass weight="bold" />}
      </div>
    </div>
  );
};
