import React from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { LocalStorageKeys } from '../localStorageKeys';

function assertNever(value: never, message: string) {
  throw new Error(`Unexpected value: '${value}'. ${message}`);
}

function getTrackingId() {
  switch (APP_CONFIG.environment) {
    case 'local':
      return 'G-8X7HHHW4P2';
    case 'staging':
      return 'G-4KX0GWJ3VT';
    case 'prod':
      return 'G-S5YYHZ98N6';
    default:
      assertNever(APP_CONFIG.environment, 'Unexpected environment');
      throw new Error();
  }
}

interface IGoogleAnalyticsContext {
  isEnabled: boolean;
  setEnabled: (enabled: boolean) => void;
  sendPageAnalytics: () => void;
}

export const GoogleAnalyticsContext = React.createContext<IGoogleAnalyticsContext>({
  isEnabled: false,
  setEnabled: () => {},
  sendPageAnalytics: () => {},
});

export const GoogleAnalyticsContextProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [isEnabled, setInternalIsEnabled] = React.useState(false);
  const [previousIsEnabled, setIsPreviousEnabled] = React.useState(false);

  const location = useLocation();

  React.useEffect(() => {
    const gaEnabled = localStorage.getItem(LocalStorageKeys.GoogleAnalytics);
    const isGaEnabled = !!gaEnabled && gaEnabled === 'true';
    setInternalIsEnabled(isGaEnabled);
  }, [setInternalIsEnabled]);

  const setEnabled = React.useCallback(
    (enabled: boolean) => {
      setIsPreviousEnabled(isEnabled);
      setInternalIsEnabled(enabled);
    },
    [isEnabled, setIsPreviousEnabled, setInternalIsEnabled]
  );

  React.useEffect(() => {
    if (isEnabled) {
      ReactGA.initialize(getTrackingId());
      console.log('GA Enabled');
    } else if (!isEnabled && previousIsEnabled) {
      window.location.reload();
    } else {
      console.log('GA Disabled');
    }

    if (isEnabled !== previousIsEnabled) {
      localStorage.setItem(LocalStorageKeys.GoogleAnalytics, `${isEnabled}`);
    }
  }, [isEnabled, previousIsEnabled]);

  const sendPageAnalytics = React.useCallback(() => {
    if (isEnabled) {
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname,
      });
    }
  }, [isEnabled]);

  React.useEffect(() => sendPageAnalytics(), [location, sendPageAnalytics]);

  return (
    <GoogleAnalyticsContext.Provider value={{ isEnabled, setEnabled, sendPageAnalytics }}>
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export function useGoogleAnalyticsContext() {
  const ctx = React.useContext(GoogleAnalyticsContext);
  if (!ctx) {
    throw new Error('No Google Analytics Context');
  }
  return ctx;
}
