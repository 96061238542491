import * as React from 'react';

export function useIsSmallScreen() {
  const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth <= 1056);

  React.useLayoutEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1056);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isSmallScreen;
}
